import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';

export class User {
	username: string;
	//password?:string;
	token_login?: string;
	loggedIn?: boolean;
}


@Injectable({
	providedIn: 'root'
})
export class LoginService {

	user: User;
	loginUrl;
	hasAccessList = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient, private _cookieService: CookieService) { }

	setIsLoggedIn(isLogged) {
		this.user.loggedIn;
	}

	getIsLoggedIn() {
		return this.getSessionId() ? true : false;
	}

	getLoginUrl() {
		return environment.apiUrl;
	}

	getSessionId() {
		return this._cookieService.get('rcn_clap')
	}

	setSession(user: User) {
		this.user = user;
		localStorage.setItem('user', JSON.stringify({
			user: this.user.username
		}));
	}

	logIn(user: User) {
		return this.http
			.post(environment.apiUrl, user)
			.pipe(map((response: Response) => response));
	}

	getHiddenToken() {
		return this.http
			.get(environment.apiUrl + "/home/index")
			.pipe(map((response: Response) => response));
	}

	validateLogin(user: User) {

		var mapForm = document.createElement("form");
		mapForm.method = "POST";
		mapForm.action = environment.apiUrl + "/ldap/validate_token";

		Object.keys(user).forEach(function (param) {
			var mapInput = document.createElement("input");
			mapInput.type = "hidden";
			mapInput.name = param;
			mapInput.setAttribute("value", user[param]);
			mapForm.appendChild(mapInput);
		});

		document.body.appendChild(mapForm);
		mapForm.submit();
	}

	logOut() {
		localStorage.removeItem('user');
		sessionStorage.removeItem('clap_access');
		this.hasAccessList.next(false);
		setTimeout(_ => {
			window.location.href = environment.apiUrl + "/ldap/logOut";
		}, 500);
	}

	getUserSession() {
		return this.http
			.get(environment.apiUrl + "/ldap/getUser", {})
			.pipe(map((response: any) => {
				sessionStorage.setItem('clap_access', JSON.stringify(response));
				this.hasAccessList.next(true);
				return response;
			}));
	}

	setRedirecUrl(state) {
		this._cookieService.put('returnUrl', state.url, { 'expires': new Date(2038, 1, 1) });
	}

	getUser() {
		if (sessionStorage.getItem('clap_access')) {
			return JSON.parse(sessionStorage.getItem('clap_access'));
		}
	}
}
