import { compileInjectable, ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostListener, ViewChild } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { GraficoConfig, GraficoTipo } from './interfaces/grafico.config'
import html2canvas from 'html2canvas';
import { GlobalService } from '../services/global.service';


@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.css']
})
export class GraficoComponent implements OnInit {
  
  _customGrafico
  @ViewChild('customGrafico', { static: false }) set content(content) {
    if (content) {
      this._customGrafico = content
    }
  };

  @ViewChild('contenedorGrafico', { static: false }) contenedorGrafico;

  @Input() config: GraficoConfig;
  @Input() data: any;
  @Input() dataPercent: any;
  @Input() graficaDinamica: boolean = false;
  @Input() navegacionGraficas: any;
  @Input() total: any;
  @Output() cambioGrafica = new EventEmitter();
  
  GraficoTipo = GraficoTipo 
  
  datosGrafico: any;
  grafico: GoogleChartInterface
  
  enPorcentaje: boolean = false;
  
  tamZoom: number = 5;
  zoomActual: number = 10;
  
  tamPaginacion: number = 10;
  paginaActual: number = 1;
  paginasTotal: number = 0;
  
  circularGraph: any;
  altoGrafica = 500 //(window.innerHeight - 235) 
  
  estaExportando: boolean = false
  openDrop: boolean = false;

  selectedInfo: boolean = false;

  contextRole($event) {
		this.selectedInfo= true;
		this._gS.onRightClick($event, 3);
	}
  
  get OpcionesGenerales() {
    return {
      height: (this.altoGrafica ) - 50,
      chartArea: {
        width: '80%',
        height: '80%',
      },
      vAxis: {
        viewWindow: {
          min: 0,
        }
      },
      titleTextStyle: {
        color: '#333333',
        fontName: 'Roboto-Light', 
        fontSize: 16,
        bold: false, 
        italic: false
      },
      annotations: {
        textStyle: {
          fontSize: 10,
          color: '#333',
          bold: false
        },
        stem: {
          color: 'none'
        },
      },
      
      
    }
  }
  
  get OpcionesColumnasApiladas() {
    return {
      isStacked: true,
      annotations: {
        textStyle: {
          fontSize: 14,
          color: '#333',
          bold: true
        },
        stem: {
          color: '#333'
        },
      },
    }
  }

  get OpcionesBarras() {
    return {
      annotations: {
        textStyle: {
          color: '#333',
          fontSize: 18,
          bold: true,
        },
        stem: {
          color: 'none'
        },
       
      }
    }
  }
  
  get OpcionesPie() {
    return {
      pieHole: 0.8,
      pieSliceText: 'none',
      legend: 'none',
      chartArea: {
        width: '85%',
        height: '85%',
      },
    }
  }
  
  constructor(public _gS: GlobalService) { }
  
  ngOnInit() {
    
    this.paginarData();  
    
    this.grafico = {
      chartType: this.getTipoGrafica(this.config.tipo),
      dataTable: [this.config.columnas, ...this.datosGrafico],
      options: this.getOpciones(),
    }

  }

  loadChart(event) {
    //TO-DO load 
  }
  
  selectHandler(event) {
    
    //console.log(event)  
    
    
  }
  
  mostrarZoom() {
    return this.config.tipo == GraficoTipo.Barras ||  this.config.tipo == GraficoTipo.Lineas ||  this.config.tipo == GraficoTipo.ColumnasApiladas
  }
  
  getOpciones() {
    let opciones = { ...this.OpcionesGenerales }
    
    if (this.config.tipo == GraficoTipo.ColumnasApiladas) {
      opciones = {
        ...opciones,
        ...this.OpcionesColumnasApiladas,
      }
    }
    
    if (this.config.tipo == GraficoTipo.Pie) {
      opciones = {
        ...this.OpcionesGenerales,
        ...this.OpcionesPie,
      }
    }

    if (this.config.tipo == GraficoTipo.Barras) {
      opciones = {
        ...this.OpcionesGenerales,
        ...this.OpcionesBarras,
      }
    }
    
    opciones = {
      ...opciones,
      ...this.config.opciones
    }

    if (this.config.totales) {
      opciones.height = opciones.height - 40
    }

    if (this.config.tabla) {
      opciones.height = opciones.height - 121
    }

    return opciones;
  }
  
  getTipoGrafica(tipo: GraficoTipo) {
    switch (tipo) {
      case GraficoTipo.Barras:
      return 'BarChart'
      case GraficoTipo.Columnas:
      case GraficoTipo.ColumnasApiladas:
      return 'ColumnChart'
      case GraficoTipo.Lineas:
      return 'LineChart'
      case GraficoTipo.IndicadorCircular:
      return 'IndicadorCircular'
      case GraficoTipo.Pie:
      return 'PieChart'
      
      return null
    }
  }
  
  actualizarGrafico() {
    if (!this.grafico) {
    
      return;
    }
    
    const graficoComp = this.grafico.component;
    this.grafico.dataTable = [this.config.columnas, ...this.datosGrafico];
    
    
    graficoComp.draw();

    
  }
  
  paginarData() {
    
    const data = this.enPorcentaje ? this.dataPercent : this.data

    if (this.config.tipo == GraficoTipo.IndicadorCircular) {
      this.datosGrafico = [...data]
      return;
    }
    
    this.paginasTotal = Math.ceil(data.length / this.zoomActual);
    this.datosGrafico = [...data].slice((this.paginaActual - 1) * this.zoomActual, this.paginaActual * this.zoomActual);
    
    if (this.paginaActual > 0 || this.zoomActual != this.tamPaginacion) {
      this.actualizarGrafico()
    }
  }
  
  paginaAnterior() {
    if (this.paginaActual > 1) {
      this.paginaActual--;
      this.paginarData();
    }
  }
  
  paginaSiguiente() {
    if (this.paginaActual < this.paginasTotal) {
      this.paginaActual++;
      this.paginarData();
    }
  }
  
  zoomIn() {
    if (this.zoomActual > this.tamZoom) {
      this.zoomActual-=this.tamZoom;
      this.paginarData();  
    } else {
      if (this.zoomActual > 1) {
        this.zoomActual-=1;
        this.paginarData();  
      }
    }
    
  }
  
  zoomOut() {
    if (this.zoomActual < this.data.length) {
      if (this.zoomActual < this.tamZoom) {
        this.zoomActual += 1;
      } else {
        this.zoomActual += this.tamZoom;  
      }
      
      this.paginarData();
    }
    
    
  }
  
  getColorSerie(indexSerie) {
    return this.config.opciones.colors[indexSerie]
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const target = event.target;
    
    if (target.resizeTO) clearTimeout(target.resizeTO);
    target.resizeTO = setTimeout(() => {
      this.actualizarGrafico();
    }, 500);
    
  }
  
  cambiarDatos() {
    this.enPorcentaje = !this.enPorcentaje
    
    if (this.config.tipo == GraficoTipo.ColumnasApiladas || this.config.tipo == GraficoTipo.Lineas) {
      const opciones:any = this.getOpciones()
      opciones.vAxis = this.enPorcentaje ? {
        viewWindow: {
          max: 100,
          min: 0,
        }
      } : {}
      
      this.grafico.options = opciones
    }
    
    this.paginarData();
  }

  graficaAnterior() {
    this.cambioGrafica.emit('anterior')
  }

  graficaSiguiente() {
    this.cambioGrafica.emit('siguiente')
  }

  exportarGrafica() {
    this.openDrop = false
    this.estaExportando = true
    setTimeout(() => {
    html2canvas(this.contenedorGrafico.nativeElement, {
      imageTimeout: 0
    })
      .then(canvas => {
        //document.body.appendChild(canvas)
        this.estaExportando = false
        
        const graficaPNG = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = graficaPNG;
        a.download = `${this.config.nombre}.png`
        a.click();
      })
    }, 10)
  }

  toggleDrops() {
    this.openDrop = !this.openDrop
  }
  
  
  
}
