import { Directive, Input, forwardRef } from "@angular/core";
import { Validator, AbstractControl, NG_VALIDATORS, Validators, ValidatorFn } from "@angular/forms";

const maxDate = (max: string, required: boolean): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!required && !control.value)
      return null;

    if (!max)
      return null;

    return control.value <= max ? null:  { 'date': {value: control.value} };
  };
}

const minDate = (min: string, required: boolean): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!required && !control.value)
      return null;

    if (!min)
      return null;

    return control.value >= min ? null:  { 'date': {value: control.value} };
  };
}

@Directive({
  selector: '[min][type=date][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinDatesValidatorDirective),
    multi: true
  }]
})
export class MinDatesValidatorDirective implements Validator {

  private _validator: ValidatorFn;
  @Input('required') required: boolean;

  @Input() public set min(value: string) {
    const required_input = this.required !== undefined;
    this._validator = minDate(value, required_input);
  }

  public validate(control: AbstractControl): { [key: string]: any }{
    return this._validator ? this._validator(control) : null;
  }

}

@Directive({
  selector: '[max][type=date][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxDatesValidatorDirective),
    multi: true
  }]

})
export class MaxDatesValidatorDirective implements Validator {

  private _validator: ValidatorFn;
  @Input('required') required: boolean;


  @Input() public set max(value: string) {
    const required_input = this.required !== undefined;
    this._validator = maxDate(value, required_input);
  }

  public validate(control: AbstractControl): { [key: string]: any }{
    return this._validator ? this._validator(control) : null;
  }

}



