import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MinutesPipe } from '../../shared/pipes/minutes.pipe';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { LanguageChangeService } from '../../shared/services/language-change.service';
import { GlobalService } from '../../shared/services/global.service';
import { FilterService } from '../services/filter.service';

@Component({
	selector: 'app-tabla-parametros-dos',
	templateUrl: './tabla-parametros-dos.component.html',
	styleUrls: ['./tabla-parametros-dos.component.css']
})
export class TablaParametrosDosComponent implements OnInit {
	@Input() campos;
	@Input() info;
	@Input() showAllData?= false;
	@Input() showListChapters?= false;
	@Input() editFieldsBlocked: boolean = false;
	@Input() openPanels: any[];
	@Output() porcentaje = new EventEmitter();
	@Output() showLoader = new EventEmitter();
	@Output() openPanel = new EventEmitter();
	informacion: any;
	filteredInformacion: any;
	filt: any = { // Usada para recibir valor de los inputs de busquedas
		set_location: '',
		principal_character_month: '',
		other_character_month: '',
		character_chapter: '',
		set_studio: '',
		locations: '',
		national_travels: '',
		international_travels: '',
		set_location_lib: '',
		principal_character_month_lib: '',
		other_character_month_lib: '',
		character_chapter_lib: '',
		set_studio_lib: '',
		locations_lib: '',
		national_travels_lib: '',
		international_travels_lib: ''
	}
	porcent: any;
	loader: boolean = true;
	isFilteredLibreto = true;
	viewPanel: any = {
		character_chapter: false,
		character_chapter_lib: false,
		header_info: false,
		international_travels: false,
		international_travels_lib: false,
		list_chapters: false,
		locations: false,
		locations_lib: false,
		national_travels: false,
		national_travels_lib: false,
		other_character_month: false,
		other_character_month_lib: false,
		parameters: false,
		principal_character_month: false,
		principal_character_month_lib: false,
		set_location: false,
		set_location_lib: false,
		set_studio: false,
		set_studio_lib: false,
	};
	dataQuery: any = {
		params:{}
	};
	/* sections: any[] = [
		{
			isFilteredLibreto: true,
			filteredInformacion: 'principal_character_month_lib',
			title: 'PERSONAJES PRINCIPALES MES - LIBRETO',
			campo: 'campos_tablah_personajesprincipalesmes_lib'
		}
	] */

	toggleViewPanel(panel, category?, type?){
		if(this.filteredInformacion[panel]){
			this.viewPanel[panel]= !this.viewPanel[panel];
			return
		}
		this.dataQuery = { params:{}};
		this.dataQuery.namePanel = panel;
		this.dataQuery.nameQuery = category;
		this.dataQuery.params.lib = this.isFilteredLibreto;
		//Characters type: 1 Principal, 2 Capitular, 3 Otros personajes
		//Set Location type: 1 Locacion , 2 Set
		//Travel type: 1 Nacional , 2 Intgernacional
		if(category == 'parameter_characters'){
			this.dataQuery.params.character_type = type;
			this.dataQuery.nameResponse = 'characters';
		}
		if(category == 'parameter_set_location'){
			this.dataQuery.params.sc_location_studio_id = type;
			this.dataQuery.nameResponse = 'set_location';
		}
		if(category == 'parameter_travel'){
			this.dataQuery.params.travel_type_id = type;
			this.dataQuery.nameResponse = 'travels';
		}
		if(panel == 'locations_lib') this.dataQuery.nameResponse = 'locations';
		this.openPanel.emit(this.dataQuery);
	}

	onSearch(text, propertyOfFilter) {
		this.filt[propertyOfFilter] = text;
		this.loader = true;
		setTimeout(() => { this.filtro(); }, 100)
	}

	asignarClase(fila, campo) {
		var clases = [];
		if (campo.fija) {
			clases.push('sticky-left')
		}

		if (campo.align) {
			clases.push('align-' + campo.align)
		}

		if (campo.tipo == 'texto_fila') {
			clases.push('no-wrapp-text')
		}

		return clases.join(' ')
	}

	chequear(item, campov, info = null) {
		if (item == 'percentage') {
			let formatCampo = campov.campo.endsWith("_lib") ? campov.campo.substring(0, campov.campo.length - 4) : campov.campo;
			if (formatCampo == "studio_scenes" || formatCampo == "location_scenes" || formatCampo == "location_night_scenes") {
				for (let x of this.informacion.parameters) {
					if (x.name == formatCampo) {
						this.porcent = {
							name: x.name,
							percentage: x.percentage,
							tipo: 'parametro'
						}
						this.porcentaje.emit(this.porcent);
					}
				}
			}
		}
		else if (item == 'percent_character_month') {
			if (campov.campo == "personajes_principales_mes" || campov.campo == "personajes_principales_mes_lib") {
				let param = this.filteredInformacion.principal_character_month ? 'principal_character_month' : 'principal_character_month_lib';
				for (let x of this.filteredInformacion[param]) {
					if (x.id == info.id) {
						this.porcent = {
							name: x.character_name,
							percentage: x.percentage,
							tipo: 'personaje',
							id: x.id
						}
						this.porcentaje.emit(this.porcent);
					}
				}
			}
		}
		else if (item == 'percent_other_character_month') {
			if (campov.campo == "other_character_month" || campov.campo == "other_character_month_lib") {
				for (let x of this.filteredInformacion[campov.campo]) {
					if (x.id == info.id) {
						this.porcent = {
							name: x.character_name,
							percentage: x.percentage,
							tipo: 'personaje',
							id: x.id
						}
						this.porcentaje.emit(this.porcent);
					}
				}
			}
		}
		else if (item == 'percent_personaje_capitular') {
			if (campov.campo == "personajes_capitulares" || campov.campo == "personajes_capitulares_lib") {
				let param = this.filteredInformacion.character_chapter ? 'character_chapter' : 'character_chapter_lib';
				for (let x of this.filteredInformacion[param]) {
					if (x.id == info.id) {
						this.porcent = {
							name: x.character_name,
							percentage: x.percentage,
							tipo: 'personaje',
							id: x.id
						}
						this.porcentaje.emit(this.porcent);
					}
				}
			}
		}
		else if (item == 'percent_locs') {
			if (campov.campo == "locaciones" || campov.campo == "locaciones_lib") {
				let param = this.filteredInformacion.locations ? 'locations' : 'locations_lib';
				for (let x of this.filteredInformacion[param]) {
					if (x.id == info.id) {
						this.porcent = {
							name: x.sc_location_esp,
							percentage: x.percentage,
							tipo: 'locs',
							id: x.id
						}
						this.porcentaje.emit(this.porcent);
					}
				}
			}
		}
		else if (item == 'percent_sets_loc') {
			if (campov.campo == "set_loc" || campov.campo == "set_loc_lib") {
				let param = this.filteredInformacion.set_location ? 'set_location' : 'set_location_lib';
				for (let x of this.filteredInformacion[param]) {
					if (x.id == info.id) {
						this.porcent = '';
						this.porcent = {
							name: x.sc_location_set_esp,
							percentage: x.percentage,
							tipo: 'sets',
							id: x.id
						}
						this.porcentaje.emit(this.porcent);
					}
				}
			}
		}
		else if (item == 'percent_sets_est') {
			if (campov.campo == "set_est" || campov.campo == "set_est_lib") {
				let param = this.filteredInformacion.set_studio ? 'set_studio' : 'set_studio_lib';
				for (let x of this.filteredInformacion[param]) {
					if (x.id == info.id) {
						this.porcent = {
							name: x.sc_location_set_esp,
							percentage: x.percentage,
							tipo: 'sets',
							id: x.id
						}
						this.porcentaje.emit(this.porcent);
					}
				}
			}
		}
	}
	/**
	 * Asigna valores diferentes a parámetros generales 
	 * (Ej.: Personajes principales mes, Locaciones, Viajes, etc...)
	 * de la parte de la tabla: 
	 * Nombre, Parámetros de diseño y Desglose
	 * @param valor -
	 * @param tipo - 
	 * @param info -
	 */
	asignarValor(valor, tipo, info, fieldToFill = '') {
		if (tipo == 'nombre') {
			if (valor.campo == 'set_loc') {
				return info.sc_location_set_esp + ' - ' + info.sc_location_esp;
			} else if (valor.campo == 'set_est') {
				return info.sc_location_set_esp + ' - ' + info.sc_location_esp;
			} else if (valor.campo == 'other_character_month') {
				return info.character_name + ' (' + info.character_role_esp + ')';
			} else if (valor.campo == 'personajes_capitulares') {
				return info.character_name + ' (' + info.character_role_esp + ')';
			} else if (valor.campo == 'personajes_principales_mes') {
				return info.character_name;
			} else if (valor.campo == 'locaciones') {
				return info.sc_location_esp;
			} else if (valor.campo == 'national_travels') {
				let valueField = `${info.name} (${info.count_travels})`;
				return valueField.toUpperCase();
			} else if (valor.campo == 'international_travels') {
				let valueField = `${info.name} - ${info.name_country} (${info.count_travels})`;
				return valueField.toUpperCase();
			} else if (valor.campo == 'set_loc_lib') {
				return info.sc_location_set_esp + ' - ' + info.sc_location_esp;
			} else if (valor.campo == 'set_est_lib') {
				return info.sc_location_set_esp + ' - ' + info.sc_location_esp;
			} else if (valor.campo == 'other_character_month_lib') {
				return info.character_name + ' (' + info.character_role_esp + ')';
			} else if (valor.campo == 'personajes_capitulares_lib') {
				return info.character_name + ' (' + info.character_role_esp + ')';
			} else if (valor.campo == 'personajes_principales_mes_lib') {
				return info.character_name;
			} else if (valor.campo == 'locaciones_lib') {
				return info.sc_location_esp;
			} else if (valor.campo == 'national_travels_lib') {
				let valueField = `${info.name} (${info.count_travels})`;
				return valueField.toUpperCase();
			} else if (valor.campo == 'international_travels_lib') {
				let valueField = `${info.name} - ${info.name_country} (${info.count_travels})`;
				return valueField.toUpperCase();
			} else {
				return valor.titulo;
			}
		} else {
			if (fieldToFill == 'parameters') {
				for (let x of this.filteredInformacion.parameters) {
					if (x.name == valor.campo) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'principal_character_month') {
				for (let x of this.filteredInformacion.principal_character_month) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'other_character_month') {
				for (let x of this.filteredInformacion.other_character_month) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'character_chapter') {
				for (let x of this.filteredInformacion.character_chapter) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'set_location') {
				for (let x of this.filteredInformacion.set_location) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'set_studio') {
				for (let x of this.filteredInformacion.set_studio) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'locations') {
				for (let x of this.filteredInformacion.locations) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'national_travels') {
				for (let x of this.filteredInformacion.national_travels) {
					if (x.id == info.id) {
						if (tipo == 'percentage') {
							// Retorna información en la columna percentage que hace colspan = 2
							let floatInfoDailyScenes = parseFloat(info.daily_scenes);
							return `DV:${info.travel_days}, DT:${info.recording_days}, ESC DIA: ${floatInfoDailyScenes}`;
						}
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'international_travels') {
				for (let x of this.filteredInformacion.international_travels) {
					if (x.id == info.id) {
						if (tipo == 'percentage') {
							// Retorna información en la columna percentage que hace colspan = 2
							let floatInfoDailyScenes = parseFloat(info.daily_scenes);
							return `DV:${info.travel_days}, DT:${info.recording_days}, ESC DIA: ${floatInfoDailyScenes}`;
						}
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'principal_character_month_lib') {
				for (let x of this.filteredInformacion.principal_character_month_lib) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'other_character_month_lib') {
				for (let x of this.filteredInformacion.other_character_month_lib) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'character_chapter_lib') {
				for (let x of this.filteredInformacion.character_chapter_lib) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'set_location_lib') {
				for (let x of this.filteredInformacion.set_location_lib) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'set_studio_lib') {
				for (let x of this.filteredInformacion.set_studio_lib) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'locations_lib') {
				for (let x of this.filteredInformacion.locations_lib) {
					if (x.id == info.id) {
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'national_travels_lib') {
				for (let x of this.filteredInformacion.national_travels_lib) {
					if (x.id == info.id) {
						if (tipo == 'percentage') {
							// Retorna información en la columna percentage que hace colspan = 2
							let floatInfoDailyScenes = parseFloat(info.daily_scenes);
							return `DV:${info.travel_days}, DT:${info.recording_days}, ESC DIA: ${floatInfoDailyScenes}`;
						}
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}
			if (fieldToFill == 'international_travels_lib') {
				for (let x of this.filteredInformacion.international_travels_lib) {
					if (x.id == info.id) {
						if (tipo == 'percentage') {
							// Retorna información en la columna percentage que hace colspan = 2
							let floatInfoDailyScenes = parseFloat(info.daily_scenes);
							return `DV:${info.travel_days}, DT:${info.recording_days}, ESC DIA: ${floatInfoDailyScenes}`;
						}
						return (x[tipo] == null) ? "--" : x[tipo];
					}
				}
			}

		}
	}
	/**
	 * Asigna valores solo a los parámetros generales
	 * de la parte de la tabla:
	 * Nombre, Parámetros de diseño y Desglose
	 * @param valor 
	 * @param tipo 
	 */
	asignarValorDos(valor, tipo) {
		for (let x of this.filteredInformacion.parameters) {
			if (x.name == valor.campo) {
				for (let y of x.info_chapters) {
					if (y.number_chapter_cast === tipo) {
						if (y.total == null || y.total == undefined) {
							return '--';
						} else if (y.total == 0) {
							return 0
						} else {
							return y.total;
						}
					}
				}
			}
		}
	}
	/**
	 * Asigna valores respectivos a los capitulos.
	 * @param valor  
	 * @param tipo 
	 * @param info 
	 */
	asignarValorTres(valor, tipo, info) {
		for (let y of info.info_chapters) {
			if (Math.round(y.number_chapter) === tipo) {
				if (y.total == null || y.total == undefined) {
					return '--';
				} else if (y.total == 0) {
					return 0
				} else {
					return y.total;
				}
			}
		}
	}

	changeFilter() {
		this.isFilteredLibreto = !this.isFilteredLibreto;
		let typeExport = this.isFilteredLibreto ? 'libreto' : 'desglose';
		this.filtro();
		if(this.filteredInformacion.parameters.length){
			this.filteredInformacion.parameters = this.filteredInformacion.parameters.filter(p =>
				p.showExport == typeExport || p.showExport == 'ambos'
			);
		}
		this.campos.campos_tablav_filtered = this.campos.campos_tablav.filter(p =>
			p.showExport == typeExport || p.showExport == 'ambos'
		);
	}

	filtro() {
		this.filteredInformacion = JSON.parse(JSON.stringify(this.informacion));
		/*set_location*/
		if (this.filt.set_location != '') {
			this.filteredInformacion.set_location = this._fS.searchFilter(this.filteredInformacion.set_location, this.filt.set_location, 'sc_location_set_esp');
		}

		/*principal_character_month*/
		if (this.filt.principal_character_month != '') {
			this.filteredInformacion.principal_character_month = this._fS.searchFilter(this.filteredInformacion.principal_character_month, this.filt.principal_character_month, 'character_name');
		}

		/*other_character_month*/
		if (this.filt.other_character_month != '') {
			this.filteredInformacion.other_character_month = this._fS.searchFilter(this.filteredInformacion.other_character_month, this.filt.other_character_month, 'character_name');
		}

		/*character_chapter*/
		if (this.filt.character_chapter != '') {
			this.filteredInformacion.character_chapter = this._fS.searchFilter(this.filteredInformacion.character_chapter, this.filt.character_chapter, 'character_name');
		}

		/*set_studio*/
		if (this.filt.set_studio != '') {
			this.filteredInformacion.set_studio = this._fS.searchFilter(this.filteredInformacion.set_studio, this.filt.set_studio, 'sc_location_set_esp');
		}

		/*locations*/
		if (this.filt.locations != '') {
			this.filteredInformacion.locations = this._fS.searchFilter(this.filteredInformacion.locations, this.filt.locations, 'sc_location_esp');
		}

		/*national_travels */
		if (this.filt.national_travels != '') {
			this.filteredInformacion.national_travels = this._fS.searchFilter(this.filteredInformacion.national_travels, this.filt.national_travels, 'name');
		}

		/*international_travels */
		if (this.filt.international_travels != '') {
			this.filteredInformacion.international_travels = this._fS.searchFilter(this.filteredInformacion.international_travels, this.filt.international_travels, 'name');
		}

		/*set_location libreto*/
		if (this.filt.set_location_lib != '') {
			this.filteredInformacion.set_location_lib = this._fS.searchFilter(this.filteredInformacion.set_location_lib, this.filt.set_location_lib, 'sc_location_set_esp');
		}

		/*principal_character_month libreto*/
		if (this.filt.principal_character_month_lib != '') {
			this.filteredInformacion.principal_character_month_lib = this._fS.searchFilter(this.filteredInformacion.principal_character_month_lib, this.filt.principal_character_month_lib, 'character_name');
		}

		/*other_character_month libreto*/
		if (this.filt.other_character_month_lib != '') {
			this.filteredInformacion.other_character_month_lib = this._fS.searchFilter(this.filteredInformacion.other_character_month_lib, this.filt.other_character_month_lib, 'character_name');
		}

		/*character_chapter libreto*/
		if (this.filt.character_chapter_lib != '') {
			this.filteredInformacion.character_chapter_lib = this._fS.searchFilter(this.filteredInformacion.character_chapter_lib, this.filt.character_chapter_lib, 'character_name');
		}

		/*set_studio libreto*/
		if (this.filt.set_studio_lib != '') {
			this.filteredInformacion.set_studio_lib = this._fS.searchFilter(this.filteredInformacion.set_studio_lib, this.filt.set_studio_lib, 'sc_location_set_esp');
		}

		/*locations libreto*/
		if (this.filt.locations_lib != '') {
			this.filteredInformacion.locations_lib = this._fS.searchFilter(this.filteredInformacion.locations_lib, this.filt.locations_lib, 'sc_location_esp');
		}

		/*national_travels  libreto*/
		if (this.filt.national_travels_lib != '') {
			this.filteredInformacion.national_travels_lib = this._fS.searchFilter(this.filteredInformacion.national_travels_lib, this.filt.national_travels_lib, 'name');
		}

		/*international_travels  libreto*/
		if (this.filt.international_travels_lib != '') {
			this.filteredInformacion.international_travels_lib = this._fS.searchFilter(this.filteredInformacion.international_travels_lib, this.filt.international_travels_lib, 'name');
		}
		this.loader = false;
		return this.filteredInformacion;
	}

	adjustInfoParameters() {
		if(!this.informacion.parameters.length) return;
		//Permite conservar el orden definido en campos tablav (Para info Parameters)
		let tempCamposV = JSON.parse(JSON.stringify(this.campos.campos_tablav));
		this.informacion.parameters = tempCamposV.map(campo => {
			for (const param of this.informacion.parameters) {
				if (param.name === campo.campo) {
					param.nombre = campo.titulo
					param.showExport = campo.showExport
					return param;
				}
			}
		})
	}

	constructor(private _fS: FilterService, public _gS: GlobalService, private dP: DatePipe, private mP: MinutesPipe, private _lS: LanguageChangeService) { }

	ngOnInit() {
		this.informacion = this.info;
		/**
		 * Evalua que si llegan nulos, ponerlo en un array vacio
		 */
		if (this.informacion.national_travels === null) {
			this.informacion.national_travels = []
		}
		if (this.informacion.international_travels === null) {
			this.informacion.international_travels = []
		}

		this.filteredInformacion = JSON.parse(JSON.stringify(this.informacion));
		if (!this.showAllData) {
			this.adjustInfoParameters();
			this.isFilteredLibreto = false;
			this.changeFilter();
		} else {
			this.filtro();
		}
		
	}


	ngOnChanges(changes: SimpleChanges) {
		if(changes.info){
			this.informacion = this.info;
			this.filteredInformacion = JSON.parse(JSON.stringify(this.informacion));
		}
		if(changes.openPanels){
			this.openPanels.forEach(panel => {
				this.viewPanel[panel.namePanel] = true;
			});
		}
	 }
}
