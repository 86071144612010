import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cero'
})
export class CeroPipe implements PipeTransform {

  transform(value: any, long: any): any {
    if (!value) return null;
    else {
      let longValue = value.toString().length;
      let ite = parseInt(long) - parseInt(longValue);
      for(let i = 0; i<ite; i++) {
        value = '0' + value;
      }
      return value;
    }
  }
}
