import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
  name: 'htmlSUrl'
})
export class HtmlSanitizeUrlPipe implements PipeTransform {

	constructor(private dS: DomSanitizer){}

  transform(value: any, ...args: any[]): any {
    return this.dS.bypassSecurityTrustResourceUrl(value);
  }

}
