import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Component({
	selector: 'app-tabla-clips2',
	templateUrl: './tabla-clips2.component.html',
	styleUrls: ['./tabla-clips2.component.css']
})
export class TablaClips2Component implements OnInit {
	@Input() campos;
	@Input() info;
	@Input() showMobileMenu: boolean = true;
	@Output() OnContextClickRow = new EventEmitter();

	datos: any;
	dat: any;
	selected_item: any;
	isMobile: boolean = false;

	contextClickRow(event, data) {
		this.OnContextClickRow.emit({ $event: event, data: data });
	}

	obtenerValor(fila, campo) {
		switch (campo.tipo) {
			default:
				return fila[campo.campo] || '--'
		}
	}

	getChecked(fila, camera) {
		let cameraClipCamara = fila.camera_clip.find(cp => cp.camera_id == camera.id);
		if (!cameraClipCamara) return false;
		return cameraClipCamara.edit_cam;
	}

	subscribeChangesViewport() {
		this.breakpointObserver.observe([('(max-width: 1280px)')]).subscribe(
			data => {
				this.isMobile = data.matches;
			}
		)
	}

	constructor(private breakpointObserver: BreakpointObserver, public _gS: GlobalService) { }

	ngOnInit() {
		this.datos = this.info;
		this.subscribeChangesViewport();
	}

}
