import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Subscription } from 'rxjs';
import { GlobalService } from './shared/services/global.service';
import { LoginService } from './shared/services/login-service.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'clap';
	mostrarMenuLateral;
	logOut;
	loading: boolean = false;
	hasAccessList: boolean = false;
	subs: Subscription[] = []

	constructor(public _lS: LoginService, private _gS: GlobalService, public _cookieService: CookieService, private router: Router) {
		this.router.events.subscribe((e: RouterEvent) => {
			this.navigationInterceptor(e);
		})
	}

	ngOnInit() {
		localStorage.removeItem('queueReportClap');
		this._gS.validateDateLocalStorage();
		this.subs.push(this._lS.hasAccessList.subscribe(
			data => {
				this.hasAccessList = data;
			}
		))
	}

	statusSidebar() {

	}

	// Shows and hides the loading spinner during RouterEvent changes
	// Useful since we are using Lazy Loading Routes!
	navigationInterceptor(event: RouterEvent): void {
		if (event instanceof NavigationStart) {
			setTimeout(() => { this.loading = true }, 0);
		}
		// Set loading state to false in both of the below events to hide the spinner in case a request ends or fails
		if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
			setTimeout(() => { this.loading = false }, 0);
		}
	}

	ngOnDestroy() {
		this.subs.map(s => s.unsubscribe());
	}
}
