import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/services/global.service';
import { LoginService } from 'src/app/shared/services/login-service.service';

@Component({
	selector: 'app-error401',
	templateUrl: './error401.component.html',
	styleUrls: ['./error401.component.css']
})
export class Error401Component implements OnInit {
	loader: boolean = false;
	counter: number = 5;
	interval: any;

	goHome() {
		this.router.navigate(['/producciones']);
	}

	constructor(private _lS: LoginService, public _location: Location, private router: Router) { }

	ngOnInit() {
		this._lS.getUserSession().subscribe(
			data => {
				this.loader = false;
			}
		)
	}

	ngAfterViewInit() {
		this.interval = setInterval(() => {
			this.counter -= 1;
			if (this.counter == 0) {
				this.goHome();
			}
		}, 1000)
	}

	ngOnDestroy() {
		clearInterval(this.interval);
	}

}
