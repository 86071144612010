import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef, Output, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { GlobalService } from '../services/global.service';


@Component({
	selector: 'app-preview-escena',
	templateUrl: './preview-escena.component.html',
	styleUrls: ['./preview-escena.component.css']
})
export class PreviewEscenaComponent implements OnInit {
	@ViewChild('containerWrapper', { static: false }) containerWrapper: ElementRef;
	@Input() num_capitulos;
	@Input() isOnFullScreen: boolean = false;
	@Input() metadata;
	@Input() extra;
	// Options daily plan
	@Input() showInfoDailyPlan: boolean = false;
	@Input() canAddClip: boolean = false;
	@Input() canAddComment: boolean = false;
	@Input() clips: any[] = [];
	@Input() comments: any[] = [];
	@Input() heightAuto;
	@Input() padding: string = '2.2rem 0';
	@Input() isReportPost: boolean = false;

	@Output() onTypeClicked = new EventEmitter();
	@Output() onSaveComment = new EventEmitter();
	@Output() onEditComment = new EventEmitter();
	@Output() onDeleteComment = new EventEmitter();
	@Output() onContextClip = new EventEmitter();
	@Output() notify = new EventEmitter();

	meta: any = [];
	informacion: any[] = [];
	infotext: string;
	headers: any = {};
	clase: any = 'preview-default';
	marca_agua;
	startWithTag: boolean = false;
	accumulativeGap: number = 0;
	numberComment: number = 1;
	eventSelection: any;
	selectedComment: any;
	hoverComment: any;
	view: string = 'home';

	escena() {
		if (this.meta != null) {
			// console.log(this.meta);
			this.infotext = this.meta.split('=>').join(':');
			this.informacion = JSON.parse(this.infotext);
			this.setInfoMetadata();
			this.setHighlightsListeners();
		}
	}

	setInfoMetadata() {
		const FIRST_GAP = 10;
		const SECOND_GAP = 13;
		const SPACE_GAP = 3;
		let startChar = 1;
		this.numberComment = 1;

		this.informacion = this.informacion.map((info, index) => {
			// char_from = first character
			// char_to = last character.
			info['char_from'] = startChar + FIRST_GAP + info.type.length + SECOND_GAP;
			info['char_to'] = info['char_from'] + (info.text.length - 1);
			info['index'] = index;
			startChar = info['char_to'] + SPACE_GAP + 1;
			this.setInnerHTML(info);
			return info;
		});

		this.startWithTag = false;
		this.accumulativeGap = 0;
	}

	intExt(id) {
		if (id == 1) {
			return 'INT';
		} else if (id == 2) {
			return 'EXT';
		}
	}
	estadoDia(id) {
		if (id == 1) {
			return 'DÍA';
		} else if (id == 2) {
			return 'NOCHE';
		} else if (id == 3) {
			return 'ATARDECER';
		} else if (id == 4) {
			return 'AMANECER';
		}
	}

	mostarMarcaAgua(i) {

		if (this.marca_agua < 40) {
			this.marca_agua++;
			return false;
		}
		else {
			this.marca_agua = 0;
			return true;
		}
	}

	openType(ev, type) {
		if (type == 'clip' && !this.canAddClip ||
			type == 'comment' && !this.canAddComment) return;
		this.onTypeClicked.emit({ 'ev': ev, 'type': type });
	}

	getTotalType(type) {
		switch (type) {
			case 'clip':
				return this.clips.length;
			case 'comment':
				return this.comments.length;
			default:
				return 0;
		}
	}

	getCamerasClip(clip) {
		// Post production info
		if (clip.cameras) return `(${clip.cameras.replace(/Cam/g, '').trim()}) `

		// Daily Plan
		let camerasUsed = [];
		if(clip.camera_clip){
			camerasUsed = clip.camera_clip.filter(cp => cp.edit_cam).map(cp => cp.name_camera.replace('Cam ', ''));
		}
		if (!camerasUsed.length) return '';
		return `(${camerasUsed.join(' ')}) `
	}

	getCharSpan(char, innerIndex, info) {
		return `<span data-section-index="${info.index}" data-inner-index="${innerIndex}" data-meta-index="${innerIndex + info.char_from}">${char}</span>`
	}

	setInnerHTML(info) {
		let foundComments = this.comments.filter(c => !c.isPainted);
		const SPACE_GAP = 3;

		// No comments left, returns normal text.
		if (!foundComments.length) {
			let innerText = "";
			[...info.text].forEach((char, index) => {
				innerText += this.getCharSpan(char, index, info);
			});
			info['innerHTML'] = innerText;
			return;
		}

		let innerText = "";
		let indexComment = 0;
		let pendingClose = false;
		let startHighlight = 0;
		[...info.text].forEach((char, index) => {

			// No more comments left to validate.
			if (indexComment == foundComments.length) {
				innerText += this.getCharSpan(char, index, info);
				return;
			}

			// Comments exists
			startHighlight = this.startWithTag ? 0 : foundComments[indexComment].char_from - info.char_from;
			let characters = (foundComments[indexComment].char_to - foundComments[indexComment].char_from);
			if (this.startWithTag) {
				characters -= this.accumulativeGap;
			}
			let endHighlight = startHighlight + characters;

			if (index == startHighlight) {
				innerText += `<span class="highlight cursor-context" data-comment-id="${foundComments[indexComment].id}">${this.getCharSpan(char, index, info)}`;
				pendingClose = true;
				this.startWithTag = false;
			} else if (index == endHighlight) {
				innerText += `${this.getCharSpan(char, index, info)}<span class="number">${this.numberComment}</span></span>`;
				foundComments[indexComment].isPainted = true;
				indexComment++;
				pendingClose = false;
				this.startWithTag = false;
				this.accumulativeGap = 0;
				this.numberComment++;
			} else {
				innerText += this.getCharSpan(char, index, info);
			}
		});

		// In case tag spans to another section, it needs to be closed.
		if (pendingClose) {
			innerText += `</span>`;
			this.accumulativeGap += (info.char_to - info.char_from) - SPACE_GAP;
			this.startWithTag = true;
		}

		info['innerHTML'] = innerText;
		return;
	}

	onSelectedText(ev) {
		this.eventSelection = ev;
	}

	update() {
		this.notify.emit();
	}

	saveComment(ev) {
		this.onSaveComment.emit(ev);
	}

	/**
	 * Adds listeners to highlights since there are inserted through innerHTML.
	 */
	setHighlightsListeners() {
		setTimeout(() => {
			let highlights = this.elementRef.nativeElement.querySelectorAll('.highlight');
			highlights.forEach((highlight) => {
				this.onContextComment(highlight);
				this.onHoverComment(highlight);
			});
		}, 500)
	}

	emitActionComment(action) {
		if (action == 'edit') {
			this.onEditComment.emit(this.selectedComment);
		} else if (action == 'delete') {
			this.onDeleteComment.emit(this.selectedComment);
		}
	}

	hideMenu() {
		this._gS.hidemenu();
		this.selectedComment = null;
	}

	changeView(ev) {
		this.view = ev;
	}

	onContextComment(highlight) {
		highlight.addEventListener('contextmenu', (ev) => {
			ev.preventDefault();
			let commentId = ev.currentTarget['dataset'].commentId;
			this._gS.onRightClick(ev, 3);
			this.selectedComment = this.comments.find(c => c.id == commentId);
		});
	}

	contextCommentPanel(ev, comment) {
		this._gS.onRightClick(ev, 3);
		this.selectedComment = comment;
	}

	contextClip(event, clip) {
		this.onContextClip.emit({ $event: event, data: clip });
	}

	onHoverComment(highlight) {
		highlight.addEventListener('mouseover', (ev) => {
			ev.preventDefault();
			// if (this.isReportPost) return;
			let commentId = ev.currentTarget['dataset'].commentId;
			this.hoverComment = this.comments.find(c => c.id == commentId);
			this.highlightHoverComments();
		});
		highlight.addEventListener('mouseout', (ev) => {
			ev.preventDefault();
			// if (this.isReportPost) return;
			this.removeHighlightHoverComments();
			this.hoverComment = null;
		});
	}

	highlightHoverComments() {
		if (!this.hoverComment) return;
		let items = this.containerWrapper.nativeElement.querySelectorAll(`[data-comment-id='${this.hoverComment.id}']`)
		items.forEach(item => {
			item.classList.add('green');
		});
	}

	removeHighlightHoverComments() {
		if (!this.hoverComment) return;
		let items = this.containerWrapper.nativeElement.querySelectorAll(`[data-comment-id='${this.hoverComment.id}']`)
		items.forEach(item => {
			item.classList.remove('green');
		});
	}

	enterComment(ev) {
		// if (this.isReportPost) return;
		this.hoverComment = ev;
		this.highlightHoverComments();
	}

	exitComment() {
		// if (this.isReportPost) return;
		this.removeHighlightHoverComments();
		this.hoverComment = null;
	}

	goToCommentSide(comment) {
		if (comment.plainComment) return;
		let item = this.containerWrapper.nativeElement.querySelectorAll(`[data-comment-id='${comment.id}']`)
		if (item.length) {
			setTimeout(() => {
				item[0].scrollIntoView({ behavior: "smooth" });
			}, 100)
		}
	}

	constructor(
		private elementRef: ElementRef,
		public _gS: GlobalService,
		private cdref: ChangeDetectorRef,
		private _fS: FilterService) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.comments || changes.clips || changes.metadata || changes.extra) {
			this.meta = this.metadata;
			this.headers = this.extra;
			this.comments = this.comments.map(c => { c.isPainted = false; return c });
			this.escena();
		}
	}

	ngOnInit() {
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();
	}

}
