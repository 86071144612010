// Items that will be deleted if there is 
// a mismatched between enviroment/localStorageDate version and
// user local storage. 

export const LOCAL_STORAGE_ITEMS = [
	'pagination_ex',
	'datos_tabla_length',
	'array',
	'pagedArray',
	'escenas_filtros',
	'daily_plans_filters',
	'users_filters',
	'escenas_filtros'
];