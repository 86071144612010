import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-page-input',
  templateUrl: './page-input.component.html',
  styleUrls: ['./page-input.component.css']
})
export class PageInputComponent implements OnInit {
	@Input() appModel: any;
	@Input() requerido = false;
	@Output() appModelChange = new EventEmitter<string>();

	pages:string;
	fraction:string;

	onChangeHandler(){
		if (!this.pages && this.fraction) {
			this.pages = '0'
		}
		var a:number = this.pages ? parseInt(this.pages) : 0;
		var b:number = this.fraction ? parseInt(this.fraction) : 0;
		this.appModel = a + (b/8)
		if(b > 7){
			this.appModel = undefined
		}
		this.appModelChange.emit(this.appModel);
	}

  constructor() { }

  ngOnChanges(changes: SimpleChanges){
  	if(changes.appModel){
  		if (changes.appModel.currentValue) {
  			var a = Math.floor(changes.appModel.currentValue)
  			var b = (parseFloat(changes.appModel.currentValue) - a) * 8
  			this.pages = a.toString();
  			this.fraction = b.toString();
  		}
  	}
  }
  ngOnInit() {
  }

}
