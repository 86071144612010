import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent,HttpHeaders , HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import {LoginService } from './login-service.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

	constructor(private _cookieService: CookieService, private router: Router, private _loginService: LoginService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this._cookieService.get("rcn_clap");


		let request = req;

		if(token){

			request = req.clone({
				setHeaders: {
					'token': token
				}
			})
		}

		return next.handle(request).pipe(
			tap( event => {
				if (event instanceof HttpResponse) {
					if(event.body){
						if(event.body.msg == "Sesión invalida"){
							this._cookieService.remove('rcn_clap');
							this._loginService.logOut();
						}
					}
				}
				
			}), 
			catchError((err: HttpErrorResponse) => {

				if (err.status === 401) {
					//this.router.navigateByUrl('/login');
					this._loginService.logOut();

				}

				return throwError( err );

			}));
	}


}
