import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, enableProdMode, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { LangChangePipe } from './pipes/lang-change.pipe';

//import { CookieService } from 'ngx-cookie';
import { CookieModule } from 'ngx-cookie';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { SharedModule } from './shared/shared.module';

import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es-CO';
import { LayoutModule } from '@angular/cdk/layout';
import { Error401Component } from './errors/error401/error401.component';
registerLocaleData(localeES);


@NgModule({
	declarations: [
		AppComponent,
		Error401Component,
		// LangChangePipe
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpModule,
		HttpClientModule,
		SharedModule,
		CookieModule.forRoot(),
		BrowserAnimationsModule,
		LayoutModule
	],
	providers: [
		//CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true
		},
		{ provide: LOCALE_ID, useValue: "es-CO" }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
