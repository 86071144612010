import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'table-select-control',
	templateUrl: './table-select-control.component.html',
	styleUrls: ['./table-select-control.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TableSelectControlComponent),
			multi: true
		}]
})
export class TableSelectControlComponent implements OnInit {

	propagateChange = (_: any) => { };

	@Input() appModel: any;
	@Input() list_data;
	@Input() list_data_headers;
	@Input() list_types;
	@Input() name_type;
	@Input() name_value;
	@Input() element;
	@Input() title;
	@Input() type_id;
	@Input() component_type_id;
	@Output()

	list_filter_data: any = [];
	items_selected: any = [];
	nuevo: any = {};
	es_nuevo: boolean;

	constructor() { }

	ngOnInit() {

		this.list_filter_data = JSON.parse(JSON.stringify(this.list_data.map(item => {
			item.isUse = false;
			return item;
		})));
	}

	ngOnChanges(changes) {

		if (changes.list_data) {
			this.list_filter_data = JSON.parse(JSON.stringify(changes.list_data.currentValue));
		}

	}

	filtrarLista() {

		this.es_nuevo = false;

		if (this.nuevo.nombre) {
			let exist = this.list_data.find(x => x.name == this.nuevo.nombre.value);
			this.es_nuevo = !exist ? true : false;
		}

		// if(this.es_nuevo){
		// 	return;
		// }

		this.list_filter_data = JSON.parse(JSON.stringify(this.list_data.filter(x => !x.isUse)));

		if (this.nuevo.type)
			this.list_filter_data = this.list_filter_data.filter(x => x.type == this.nuevo.type);

		this.nuevo.nombre = null;
	}

	removerElement(elemento) {
		// console.log('elemento seleccionado', elemento);
		let ix_delete = this.items_selected.findIndex(x => x.name == elemento.name);
		// console.log('ix_delete', ix_delete);	
		if (ix_delete > -1) {
			this.items_selected.splice(ix_delete, 1);
		}

		let list_index = this.list_data.findIndex(x => x.name == elemento.name);

		this.list_data[list_index].isUse = false;
		this.filtrarLista();

		this.propagateChange(this.items_selected)
	}

	nuevoElement() {
		// console.log('n', this.nuevo);
		this.list_filter_data = JSON.parse(JSON.stringify(this.list_data));
		this.nuevo.type_name = this.list_types.find(x => x.id == Number(this.nuevo.type))[this.name_type]
		this.nuevo.name = this.nuevo.nombre.value;
		this.nuevo.isUse = true;


		this.items_selected.push(this.nuevo);
		this.list_data.push(this.nuevo);




		setTimeout(() => {
			this.nuevo = {};
			this.filtrarLista();
		}, 100)

		this.es_nuevo = false;
		this.propagateChange(this.items_selected)
	}

	seleccionElemento(selected_item) {

		if (typeof selected_item !== 'object' || selected_item === null) {
			return;
		}

		if (!selected_item.isNew) {
			this.es_nuevo = false;
			let item = this.list_data.find(x => x.id == selected_item.value);

			if (item) {
				let type_names = this.list_types.find(x => x.id == item.type);

				item = JSON.parse(JSON.stringify(item));

				item.type_name = type_names ? this.list_types.find(x => x.id == item.type)[this.name_type] : null
				this.items_selected.push(item);

				let list_index = this.list_data.findIndex(x => x.name == item.name);
				this.list_data[list_index].isUse = true;

				this.filtrarLista();


				this.propagateChange(this.items_selected);
			}

			setTimeout(() => {
				this.nuevo = {};
			}, 100)
		} else {


			let exist = this.list_data.find(x => x.name == selected_item.value);
			this.es_nuevo = !exist ? true : false;




		}

	}

	nuevoPersonaje(event) {
		// console.log('ESCRIBIR', event);
		if (event) {
			this.es_nuevo = true;
			// console.log('this.es_nuevo',this.es_nuevo);
		}

	}

	agregarTipo() {
		// console.log(this.items_selected)
	}

	cargarSeleccionados(items_selected) {

		this.list_data = this.list_data.map(item => {
			let inUse = items_selected.some(x => x.name == item.name);
			item.isUse = inUse ? true : false;
			return item;
		})

		this.filtrarLista();
	}

	writeValue(value: any) {
		if (value) {
			this.items_selected = value;
			this.cargarSeleccionados(value);
		}
	}

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched() { }

}
