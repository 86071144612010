import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { Error401Component } from './errors/error401/error401.component';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
	{
		path: 'producciones',
		loadChildren: './producciones/productions.module#ProductionsModule',
		canActivate: [AuthGuard]
	},
	{
		path: 'casting',
		loadChildren: './casting/casting.module#CastingModule',
		canActivate: [AuthGuard]
	},
	{
		path: 'admin',
		loadChildren: './admin/admin.module#AdminModule',
		canActivate: [AuthGuard]
	},
	// {
	// 	path: 'login',
	// 	loadChildren: './home/home.module#HomeModule',
	// },
	{
		path: '401',
		canActivate: [AuthGuard],
		component: Error401Component
	},
	{
		path: '**',
		redirectTo: 'producciones'
	}

]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
