import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "spaceToListJoin",
})
export class SpaceToListJoinPipe implements PipeTransform {
  transform(joinnedList: string): any {
    let splitList = joinnedList.split(",");
    return splitList.join(", ");
  }
}
