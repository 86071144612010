import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../services/filter.service';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  constructor(private _fS: FilterService){
  }

  transform(list: any, argument: string, value: any): any {
   return this._fS.filterByArgument(list, argument, value);
  }

}
