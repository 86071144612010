import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-minutes-input',
  templateUrl: './minutes-input.component.html',
  styleUrls: ['./minutes-input.component.css']
})
export class MinutesInputComponent implements OnInit {
	@Input() appModel:any;
	@Input() requerido = false;
	@Input() autoFocus: boolean = false;
	@Output() appModelChange = new EventEmitter<string>();

	minutes:any;
	seconds:any;
	onChangeHandler(){
		if (!this.minutes && this.seconds) {
			this.minutes = '0'
		}
		
		var a = this.minutes ? parseInt(this.minutes) : 0; 
		var b = this.seconds ? parseInt(this.seconds) : 0;
		// console.log('minutes', this.minutes, 'seconds',this.seconds);

		this.appModel = (a * 60) + b;
		if(b > 59){
			this.appModel = undefined
		}
		this.appModelChange.emit(this.appModel);
	}
	setValues(ev){
		if(!ev){
			this.minutes = null
			this.seconds = null
			return;
		}
		this.minutes = Math.floor(ev / 60).toString();
		this.seconds = (ev - (Math.floor(ev / 60) * 60)).toString();
	}

	complete(){
		if(this.seconds < 10 && this.seconds > 0){
			if(String(this.seconds).length < 2){
				this.seconds = '0'+ String(this.seconds);
			}else{
				this.seconds = String(this.seconds);
			}
		}else if(this.seconds == 0){
			this.seconds = '00';
		}
	}


  constructor(private elem: ElementRef) { }

  ngOnChanges(changes: SimpleChanges){
  	// console.log(changes)
  	if(changes.appModel){
  		if (changes.appModel) {
  			this.setValues(changes.appModel.currentValue)
  		}
  	}
  }

  ngOnInit() {
  	// console.log(this.appModel)
  	this.complete();
	}
	
	ngAfterViewInit(){
		if(this.autoFocus){
			let element = this.elem.nativeElement.querySelector('#minutes');
			element.focus();
		}
	}

}
