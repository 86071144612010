import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { environment } from  '../../../environments/environment';


@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	@Input() anchoFooter:any;
	
	derechos:string = "Todos los derechos reservados.";
	version:string;

 	constructor() {
 		this.version = environment.version;
 	}

 	ngOnInit() {
	}

}
