import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import {Translate_ENG} from '../../languages/language_eng';
import {Translate_ESP} from '../../languages/language_esp';

@Injectable({
  providedIn: 'root'
})
export class LanguageChangeService {
	getLenguage(){
    return this._cookieService.get('lenguage') ? this._cookieService.get('lenguage') : 'esp';
  }

  setLenguage(lang){
    this._cookieService.put('lenguage', lang);
  }
  
  changeLenguage(ev:string){
    if (ev != this.getLenguage()){
        if (this._cookieService.get('lenguage') === 'eng'){
            this._cookieService.put('lenguage', 'esp');
        } else {
            this._cookieService.put('lenguage', 'eng');
        }
        location.reload()
    }
          
  }

  constructor(private _cookieService:CookieService) { 
    var lenguaje = this.getLenguage();
    this.setLenguage(lenguaje);
  }
}
