import { Injectable } from "@angular/core";
import { LanguageChangeService } from "./language-change.service";
import { LoginService } from "./login-service.service";
import tippy from "tippy.js";
import { DatePipe } from "@angular/common";
import * as _ from "lodash";
import { CookieService } from "ngx-cookie";
import { ActivatedRoute } from "@angular/router";
import { has } from "lodash";
import { environment } from "src/environments/environment";
import { LOCAL_STORAGE_ITEMS } from "../config/local_storage_items";
import { DailyPlanStatus } from "../config/daily_plan_statuses";

@Injectable({
	providedIn: "root",
})
export class GlobalService {
	estadoSideBar: any = false;
	showContext: boolean = false;
	contextmenux = "0px";
	contextmenuy = "0px";

	horas: any = [
		{ hora: "01", value: 1 },
		{ hora: "02", value: 2 },
		{ hora: "03", value: 3 },
		{ hora: "04", value: 4 },
		{ hora: "05", value: 5 },
		{ hora: "06", value: 6 },
		{ hora: "07", value: 7 },
		{ hora: "08", value: 8 },
		{ hora: "09", value: 9 },
		{ hora: "10", value: 10 },
		{ hora: "11", value: 11 },
		{ hora: "12", value: 12 },
	];
	minutos: any;
	horarios: any = [
		{ horario: "AM", value: "AM" },
		{ horario: "PM", value: "PM" },
	];

	nombresFiltrarArgumentosExcel: any = {
		locacion: "Locación",
		set: "Set",
		tipo_evento: "Tipo evento",
		rol: "Rol",
		contrato: "Contratación",
		nombre: "Nombre",
		incluir_desarrollo: "ESC en desarrollo",
		loc_est: "Loc / Est",
		int_ext: "Int / Ext",
		dia_noche: "Día / Noche",
		cap_desde: "Capítulos desde",
		cap_hasta: "Capítulos hasta",
		tipo_exportacion: "Tipo de exportación",
	};

	selectedProjectId: any;
	tippyInstances = [];

	public regExp = {
		email: /([A-Z|a-z|0-9|\-!#$%&'*+_](\.){0,1})+[A-Z|a-z|0-9|\-!#$%&'*+_]\@([A-Z|a-z|0-9|\-!#$%&'*+_])+((\.){0,1}[A-Z|a-z|0-9]){1}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?/,
		phone: /^[0-9]{10}$/,
		orderNumber: /^[0-9]{5}$/
	};

	validateAccess(data: { id: number; type: string }, routeProjId?: number) {
		let access = JSON.parse(sessionStorage.getItem("clap_access"));
		let user = access.user;

		// Production user
		if (user.user_type_id == 4) {
			let project_id = routeProjId ? routeProjId : this.selectedProjectId;
			if (!project_id || data.id == 1 || data.id == 24 || data.id == 31) {
				//Producciones 1 - Casting 24 - Admin 31
				return this.checkAccessNoProjectPage(data, access.secure_productions);
			} else {
				return this.checkAccessProjectPage(data, access.secure_productions, project_id, routeProjId);
			}
		}
		// Power user, Executive, Admin
		else {
			return this.checkAccessSecurePages(data, access.secure_pages);
		}
	}

	checkAccessProjectPage(data, secure_productions, project_id, routeProjId) {
		let index = secure_productions.findIndex((p) => p.production_id == project_id);
		if (index >= 0) {
			let hasAccess = this.checkAccessSecurePages(data, secure_productions[index].secure_pages);
			this.selectedProjectId = routeProjId ? routeProjId : this.selectedProjectId;
			return hasAccess;
		}
		this.selectedProjectId = null;
		return false;
	}

	checkAccessSecurePages(data, secure_pages) {
		for (let page of secure_pages) {
			if (page.id == data.id && Number.isInteger(page.access)) {
				if (data.type == "read" && page.access >= 0) {
					if (data.id == 25) return this.validateAdvancesAccess();
					return true;
				} else if (data.type == "write" && page.access == 1) {
					if (data.id == 25) return this.validateAdvancesAccess();
					return true;
				}
			}
		}
		return false;
	}

	checkAccessNoProjectPage(data, secure_productions) {
		for (const sp of secure_productions) {
			let hasAccess = this.checkAccessSecurePages(data, sp.secure_pages);
			if (hasAccess) {
				return true;
			}
		}
		return false;
	}

	validateAdvancesAccess(): boolean {
		let userHasCards = JSON.parse(sessionStorage.getItem("clap_access")).user_has_cards;

		if (userHasCards) {
			return true;
		} else {
			return this.validateAccess({ id: 26, type: "read" });
		}
	}

	itemIdioma(espanol, ingles) {
		if (this._languageChangeService.getLenguage() == "esp") {
			return espanol;
		} else {
			return ingles;
		}
	}

	onRightClick(ev, elements, offsetX = 210, offsetY = 20, overflow?: number) {
		event.preventDefault();
		this.showContext = true;
		var x = 0;
		var y = 0;
		var boxHeigth = elements * offsetY + 16;
		// console.log('boxHeigth', boxHeigth,ev);

		if (ev.clientY < window.innerHeight - (boxHeigth + 5)) {
			y = ev.clientY;
		} else {
			y = window.innerHeight - (boxHeigth + 5);
		}
		if (ev.clientX < window.innerWidth - offsetX) {
			x = ev.clientX;
		} else {
			x = window.innerWidth - offsetX;
		}

		if (overflow == 2) {
			if (y > 790) y -= 80;
		}
		else if (overflow == 1) {
			if (y > 770) y -= 140;
		}
		else if (overflow == 3) {
			if (y > 780) y -= 95;
		}

		this.contextmenuy = y + "px";
		this.contextmenux = x + "px";
		
		// console.log('x', this.contextmenux, 'y', this.contextmenuy);
	}

	hidemenu() {
		this.showContext = false;
	}

	get lodash() {
		return _;
	}

	searchFilter(list, evn, value) {
		var returnList = [];
		if (evn) {
			returnList = list.filter((element) => {
				var text = eval("element." + value);
				if (text) {
					text = text.toString();
					var a = text.toLowerCase();
					var aev = evn.toLowerCase();
					return a.indexOf(aev) > -1;
				} else {
					return false;
				}
			});
		} else {
			returnList = list;
		}

		return returnList;
	}

	findFilter(list, evn, value) {
		// This filter returns one element whut the value on option
		if (value) {
			return list.find((element) => {
				var text = eval("element." + value);
				text = text.toString();
				var a = text.toLowerCase();
				var aev = evn.toLowerCase();
				return a == aev;
			});
		}
	}

	ElementInList(list, list_id, elements_id) {
		var returnData = [];
		// console.log(list, list_id, elements_id)
		if (list && elements_id) {
			for (let item of list) {
				var copyItem = false;
				var a = eval("item." + list_id);
				if (a) {
					if (a.toString() == elements_id.toString()) {
						returnData.push(item);
					}
				}
			}
		} else {
			return list;
		}
		return returnData;
	}

	getUser() {
		return this._lS.getUserSession();
	}

	getCurrentUser() {
		return JSON.parse(sessionStorage.getItem("clap_access")).user;
	}

	cssPagedMedia(size, spaceForPageNumber: boolean = false) {
		var style = document.createElement("style");
		const SPACE_BOTTOM = '36px';
		document.head.appendChild(style);
		if (spaceForPageNumber) {
			style.innerHTML = `@page {size: ${size}; margin-bottom: ${SPACE_BOTTOM}}`;
		} else {
			style.innerHTML = "@page {size: " + size + "}";
		}
		setTimeout((_) => {
			document.head.removeChild(style);
		}, 5000);
	}

	cssPagedMargin(margins) {
		var style = document.createElement("style");
		document.head.appendChild(style);
		style.innerHTML = "@page {margin: " + margins + "}";

		setTimeout((_) => {
			document.head.removeChild(style);
		}, 5000);
	}

	infoToolTip(ev) {
		const instance = tippy(ev, {
			duration: [1, 1],
		});
		this.tippyInstances = this.tippyInstances.concat(instance);
	}

	removeAllTooltips() {
		this.tippyInstances.map((t) => (t ? t.destroy() : null));
		this.tippyInstances = [];
	}

	obtenerDiaSemana(fecha) {
		// console.log(fecha);
		var year = fecha.split("-")[0];
		var month = fecha.split("-")[1];
		var day = fecha.split("-")[2];
		var dat = month + "/" + day + "/" + year;
		// console.log('dat', dat);

		var prod = new Date(dat);
		// console.log('prod', prod);

		var getDia = prod.getDay();
		// console.log('prod2', getDia);

		var semana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
		// console.log('prod3', semana[getDia]);

		return semana[getDia];
	}

	formatoHora24a12(item) {
		if (item == null || item == undefined || item == 0 || item == "") {
			return "--:-- --";
		}
		var x = item.split("T");
		var y = x[1].split(":");
		if (Number(y[0]) > 12) {
			var w = String(Number(y[0]) - 12);
			if (w == "10" || w == "11") {
				var z = `${w}:${y[1]} PM`;
			} else {
				var z = `0${w}:${y[1]} PM`;
			}
			// console.log('z1', z);
		} else if (Number(y[0]) == 12) {
			var w = String(Number(y[0]));
			var z = `12:${y[1]} PM`;
			// console.log('z2', z);
		} else if (Number(y[0]) == 0) {
			var z = `12:${y[1]} AM`;
			// console.log('z3', z);
		} else {
			var z = `${y[0]}:${y[1]} AM`;
			// console.log('z4', z);
		}
		return z;
	}

	formatoHora12a24(hora, minutos, horario) {
		// console.log('hora', hora, 'minutos', minutos, 'horario', horario);
		if (hora == 12 && horario == "PM") {
			var y = `12:${minutos}`;
			// console.log('y1', y);
		} else if (hora == 12 && horario == "AM") {
			var y = `00:${minutos}`;
			// console.log('y2', y);
		} else if (horario == "PM") {
			var x = Number(hora) + 12;
			var y = `${x}:${minutos}`;
			// console.log('y3', y);
		} else {
			var y = `${hora}:${minutos}`;
			// console.log('y4', y);
		}
		return y;
	}

	obtenerNombreId(list: any[], propertyName: string, id: any, idProperty = "id") {
		for (const item of list) {
			if (item[idProperty] == id) {
				return item[propertyName];
			}
		}
		return "";
	}

	trackById(index, item) {
		return item.id;
	}

	getTodayFormat(format) {
		let a = new Date();
		return this._datePipe.transform(a, format);
	}

	setvalidDateTimeZone(ev?): Date {
		let a = ev ? new Date(ev) : new Date();
		a = new Date(a.setTime(a.getTime() + a.getTimezoneOffset() * 60 * 1000));
		return a;
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	getDocumentPattern(typeDocument) {
		if (typeDocument == 3) {
			return "";
		}
		return "[0-9]+";
	}

	setProductionIdToLinkUserType(link, prodId) {
		return link.map(x => {
			if (x.startsWith(':prodId')) {
				x = prodId;
			}
			return x;
		})
	}

	validateDateLocalStorage() {
		console.log("Validates version storage")
		const VERSION_NAME = 'clap_version_date';
		let savedVersion = JSON.parse(localStorage.getItem(VERSION_NAME));
		if (savedVersion) {
			if (savedVersion == environment.localStorageDate) return;
		}
		// version mismatch, cleans localstorage
		LOCAL_STORAGE_ITEMS.map(item => localStorage.removeItem(item));
		// saves new version date
		localStorage.setItem(VERSION_NAME, JSON.stringify(environment.localStorageDate));
	}

	getSelectionHtml() {
		let html = "";
		if (typeof window.getSelection != "undefined") {
			var sel = window.getSelection();
			if (sel.rangeCount) {
				var container = document.createElement("div");
				for (var i = 0, len = sel.rangeCount; i < len; ++i) {
					container.appendChild(sel.getRangeAt(i).cloneContents());
				}
				html = container.innerHTML;
			}
		}
		return html;
	}

	getStartSelectedNode() {
		var selection = window.getSelection();
		if (selection.rangeCount > 0)
			return selection.getRangeAt(0).startContainer.parentNode;
	}

	getEndSelectedNode() {
		var selection = window.getSelection();
		if (selection.rangeCount > 0)
			return selection.getRangeAt(0).endContainer.parentNode;
	}

	getShootingStatusClass(dailyPlanStatus) {
		switch (dailyPlanStatus) {
			case DailyPlanStatus.Canceled: {
				return "bg-canceled";
			}
			case DailyPlanStatus.Private: {
				return "bg-private";
			}
			case DailyPlanStatus.Open: {
				return "bg-open";
			}
			case DailyPlanStatus.Called: {
				return "bg-called";
			}
			case DailyPlanStatus.Recording: {
				return "bg-recording";
			}
			case DailyPlanStatus.Closed: {
				return "bg-closed";
			}
		}
		return "";
	}
	setLocalStorageFiltersByProd(nameFilter: string, idProd: string, newFilters: object){
		let filterLocal = JSON.parse(localStorage.getItem(nameFilter));
		if(!Array.isArray(filterLocal)) filterLocal = [];
		let indexFilter = filterLocal.findIndex(filter => filter.id_prod == idProd);
		if(indexFilter > -1){
			filterLocal[indexFilter] = {id_prod: idProd, filters: newFilters};
		}else{
			filterLocal.push( {id_prod: idProd, filters: newFilters});
		}
		localStorage.setItem(nameFilter, JSON.stringify(filterLocal));
	}

	
	getLocalStorageFiltersByProd(nameFilter: string, idProd: string){
		let filterLocal = JSON.parse(localStorage.getItem(nameFilter));
		if(!Array.isArray(filterLocal)) return
		let filters = filterLocal.find(filter => filter.id_prod == idProd);
		return filters ? filters.filters : null;
	}

	setLocalStorageFiltersByProdTwoParams(nameFilter: string, idProd: string, idElement: string, newFilters: object){
		let filterLocal = JSON.parse(localStorage.getItem(nameFilter));
		if(!Array.isArray(filterLocal)) filterLocal = [];
		let indexFilter = filterLocal.findIndex(filter => filter.id_prod == idProd && filter.id_element == idElement);
		if(indexFilter > -1){
			filterLocal[indexFilter] = {id_prod: idProd, id_element: idElement, filters: newFilters};
		}else{
			filterLocal.push( {id_prod: idProd, id_element: idElement, filters: newFilters});
		}
		localStorage.setItem(nameFilter, JSON.stringify(filterLocal));
	}

	getLocalStorageFiltersByProdTwoParams(nameFilter: string, idProd: string, idElement: string){
		let filterLocal = JSON.parse(localStorage.getItem(nameFilter));
		if(!Array.isArray(filterLocal)) return
		let filters = filterLocal.find(filter => filter.id_prod == idProd && filter.id_element == idElement);
		return filters ? filters.filters : null;
	}

	setFiltersLS(data: any, nameLS: string, proId: any) {
		var aux = JSON.parse(localStorage.getItem(nameLS));
		if (aux) {
			let contador = 0;
			aux.forEach((element: any, index) => {
				if (element.proId == proId) {
					aux.splice(index, 1);
					aux.push(data);
					contador++;
				}
			})
			if (contador == 0) aux.push(data);
			localStorage.setItem(nameLS, JSON.stringify(aux));
		}
		else {
			let arrayFilter = [];
			arrayFilter.push(data);
			localStorage.setItem(nameLS, JSON.stringify(arrayFilter));
		}
	}

	getFiltersLS(newData: any, nameLS:string, proId: any) {
		if (localStorage.getItem(nameLS)) {
			var aux = JSON.parse(localStorage.getItem(nameLS));
			var data: any;
			var bandera = false;
			aux.forEach(element => {
				if (element.proId == proId) {
					data = element;
					bandera = true;
				}
			});
			if (!bandera) {
				data = newData;
			}
			return data;
		}
		else {
			return newData;
		}
	}

	constructor(
		private _cookieService: CookieService,
		private _datePipe: DatePipe,
		private _languageChangeService: LanguageChangeService,
		private _lS: LoginService,
		private route: ActivatedRoute
	) { }
}
