import { Directive, Input, HostBinding, HostListener, ElementRef } from '@angular/core';

@Directive({
	selector: '[appRatioImagen]'
})
export class RatioImagenDirective {

	@Input() ratio;
	@HostBinding('style.height') private altoImagen;

	constructor(private element: ElementRef) { }

	@HostListener('load', ['$event']) public onLoad(evt){
		let height = evt.target.clientHeight;
		this.altoImagen = height > this.calcularAlto() ? height :this.calcularAlto() + 'px';
	}

	@HostListener('window:resize', ['$event']) public onResize(evt){
		let height = evt.target.clientHeight
		this.altoImagen = height > this.calcularAlto() ? height :this.calcularAlto() + 'px';
	}

	calcularAlto():any{
		return Number(this.element.nativeElement.width * this.ratio);
	}



}
