import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeInnerHtml'
})
export class SanitizeInnerHtmlPipe implements PipeTransform {

  formattedText: SafeHtml;
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): any {
    console.log(value)
    this.formattedText = this.sanitizer.bypassSecurityTrustHtml (
      value.replace(/\n/g, '<br>')
    );
    return value;
  }
}