import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'noRepeatList'
})
/** Pipe use to avoid repeating items in lists. Useful for selects */
export class NoRepeatPipe implements PipeTransform {

	transform(value: any[], index: number, property_list: string, list: any[], property = 'id'): any {
		let currentValue = list[index][property_list];
		return value.filter(val => {
			const HAS_VALUE = list.find(
				el => val[property] == el[property_list] && val[property] != currentValue
			);
			return !HAS_VALUE;
		});
	}


}
