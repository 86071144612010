import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { WorkerService } from '../../services/worker.service';

@Component({
  selector: 'app-reports-window',
  templateUrl: './reports-window.component.html',
  styleUrls: ['./reports-window.component.css']
})
export class ReportsWindowComponent implements OnInit {

  @Input() list_process: any;
  @Output() close = new EventEmitter();
  @Output() notify = new EventEmitter();
  loader: boolean = false;

  deleteProcess(process){
    this.worker.deleteProcess(process);
    if(this.list_process.length == 1 || !this.list_process.length){
      this.closePopup('home');
    }
  }

  closePopup(ev) {
    this.close.emit(ev);
  }

  getProccesStatus(status){
    if(status == 'complete') return 'completado';
    else if(status == 'failed') return 'error';
    else{
      return 'generando';
    }
  }


  constructor(private worker: WorkerService, public _gS: GlobalService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.list_process){
      for (const process of this.list_process) {
        process.statusTranslated = this.getProccesStatus(process.status);
      }
      this._gS.removeAllTooltips();
    }
  }

}
