import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'hide-columns',
  templateUrl: './hide-columns.component.html',
  styleUrls: ['./hide-columns.component.css']
})
export class HideColumnsComponent implements OnInit {
  @Input() campos: any;
  @Input() titulo: string = 'Tabla';
  @Input() shouldMutate: boolean = true;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();

  fields: any;
  
  closePopup(ev){
    this.close.emit(ev);
  }

  toggleVisibility(ev) {
		if (!ev.fija) {
			ev.visible = !ev.visible;
		} else {
			ev.visible = true;
		}
	}

	submitForm(){
    if(this.shouldMutate){
      this.mutateTableFields();
    }
    this.update.emit(this.fields);
  }

  mutateTableFields(){
    for (const campo of this.campos) {
      let index = this.fields.findIndex(x => x.campo == campo.campo);
      campo.visible = this.fields[index].visible;
    }
  }

  constructor(public _gS: GlobalService) { }
  
  ngOnChanges(changes: SimpleChanges){
    if(changes.campos){
      this.fields = JSON.parse(JSON.stringify(this.campos));
    }
  }

  ngOnInit() {
  }

}
