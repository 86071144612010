import { Component, OnInit, Output, Input, EventEmitter, HostListener, ElementRef, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { GlobalService } from '../services/global.service';
import { SearchPipe } from '../pipes/search.pipe';
import { LanguageChangeService } from '../services/language-change.service';


@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
	@Input() appModel: any;
	@Input() list: any =[];
	@Input() value: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() required: boolean;
	@Input() backTranslate: boolean;
	@Input() toCreate: boolean;
	@Input() multiple: boolean;
	@Input() debug: boolean;
	@Input() showEmpty: boolean;
	
	@Input() createValue: string;
	@Input() uppercase:boolean;

	@Output() appModelChange = new EventEmitter<string>();
	@Output() createValueChange = new EventEmitter<string>();
	@Output() appChange = new EventEmitter<string>();

	filteredList:any = []; 
	inputValue:string;
	searchvalue:string;
	showOptions:boolean = false;
	sliceStart:number = 0
	sliceLength:number = 10
	sliceEnd:number = this.sliceLength

	clickEmpty(){
		this.appModel = undefined
		this.appModelChange.emit(this.appModel);
	}

	moveSlice(ev){
		if (ev == 'next') {
			this.sliceStart += this.sliceLength;
			this.sliceEnd += this.sliceLength;
		} else{
			this.sliceStart -= this.sliceLength;
			this.sliceEnd -= this.sliceLength;
		}
	}

	search(){
		this.searchvalue = this.inputValue
		var a = this.name;
		if (this.backTranslate) {
			a = this.name + '_' +this._languageChangeService.getLenguage()
		}
		this.filteredList = this.list;
		// console.log('search this.filteredList', this.filteredList);
		this.filteredList  = this._SearchPipe.transform(this.filteredList, this.searchvalue, a);

		if(this.toCreate){
			var found =[];
			var a = this.name
			var b = this.searchvalue
			found = this.filteredList.find(function(element) {
				return eval('element.'+ a).toLowerCase() == b.toLowerCase();
			});
			// console.log('a', a, 'b', b, 'found', found);
			if (found) {
				// this.createValue = undefined;
				this.createValue = this.searchvalue;
			} else {
				this.createValue = this.searchvalue;
				this.appModel = undefined;
				this.appModelChange.emit(this.appModel);
			}
			this.createValueChange.emit(this.createValue);
		}
	}
	startDropDown(){
		this.filteredList = this.list;
		this.showOptions = true;
		this.searchvalue = '';
		// this.inputValue = '';

	}
	getName(ev:string){
		// if (this.backTranslate) {
		// 	return eval('ev.' + this.name + '_' +this._languageChangeService.getLenguage());
		// }
		// return eval('ev.' + this.name);
		return eval('ev.' + this.name);

	}
	selectOption(ev){
		if (!this.multiple) {
			this.appModel = this.getValue(ev);
			this.inputValue = this.getName(ev);
			this.appModelChange.emit(this.appModel);
			this.appChange.emit(ev);
			this.showOptions = false;

			if (this.toCreate && !this.appModel) {
				this.createValue = undefined;
				this.createValueChange.emit(this.createValue);
			}
		} else{
			if (ev.selected ) {
				ev.selected = false
			} else {
				ev.selected = true
			}
			if (ev.selected) {
				this.appModel.push(ev);
				
			} else {
				var i = 0
				for(let sel of this.appModel){
					if(eval("sel." + this.value) == eval("ev." + this.value)){
						this.appModel.splice(i,1);
					}
					i ++
				}
			}
			this.appModelChange.emit(this.appModel);
			this.checkMultipleText();
		}
		
	}
	checkMultipleText(){
		var a = []
		this.inputValue = ''
		for(let obj of this.appModel){
			a.push(this.getName(obj));
		}
		this.inputValue = a.join(', ')
	}
	blur(){
		if(!this.multiple){
			if(!this.toCreate){
				this.executeBlur();
			} else {
				var found =[];
				var a = this.name
				var b = this.searchvalue
				found = this.filteredList.find(function(element) {
					var c = eval('element.'+ a)
					if(c){
						return c.toLowerCase() == b.toLowerCase();
					} else {
						return ''
					}
					
				});
				if (found) {
					this.createValue = undefined;
					this.selectOption(found);
					this.executeBlur()
				}
			}
		}
	}
	executeBlur(){
		setTimeout(() => {
			this.showOptions = false;

			if(this.appModel){
				var a =  this.value
				var c = this.appModel
				var found = this.list.find(function(element) {
					return eval('element.' + a) == c;
				});
				this.selectOption(found)
			}else{
				var a = this.name
				var b = this.searchvalue
				found = this.filteredList.find(function(element) {
					var c = eval('element.'+ a).toString()

					if(c){
						return c.toLowerCase() == b.toLowerCase();
					} else {
						return null
					}
					
				});

				if(found){
					this.selectOption(found);	
				}else{
					this.searchvalue = '';
					this.value = '';
					this.appModel = null;
				}

				

			}

		}, 300);
	}
	getValue(ev){
		if(ev)
			return ev[this.value] 

		return null;
	}
	setChange(){
		if (this.multiple) {
			var a = []
			if (this.appModel) {
				if (this.appModel.length > 0) {
					for(let item of this.list){
						item.selected = false;
						for(let obj of this.appModel){
							if(eval('item.' + this.value) == eval('obj.' + this.value)){
								item.selected = true;
								a.push(item)
							}
						}
					}
				} else {
					for(let item of this.list){
						item.selected = false;
					}
				}
			}
			this.appModel = a;
			this.appModelChange.emit(this.appModel);
			this.checkMultipleText();
		} else {
			// console.log(this.list)
			if (this.appModel) {
				for(let item of this.list){
					if (this.getValue(item).toString() == this.appModel.toString()){
						this.selectOption(item)
					}
				}
			}
		}
		this.filteredList = this.list;
	}
	constructor(private _elementRef:ElementRef, private _gS:GlobalService, private _SearchPipe:SearchPipe, private _languageChangeService: LanguageChangeService, private cdRef:ChangeDetectorRef ) { }
	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement) {
		const clickedInside = this._elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.showOptions = false
		}
	}
	ngOnChanges(changes: SimpleChanges){ 	
		if (!this.multiple) {
			if(changes.appModel && !changes.firstChange){
				if (changes.appModel.currentValue != changes.appModel.previousValue) {
					if (changes.appModel.currentValue == undefined || changes.appModel.currentValue == '') {
						this.inputValue = '';
					} else if(changes.appModel.previousValue == '' || changes.appModel.previousValue == undefined){
						
						for(let itm of this.list){
							if (changes.appModel.currentValue == eval('itm.' + this.value)) {
								this.selectOption(itm)
							}
						}
					}

				}
			}
		} else {
			if(changes.appModel && !changes.firstChange){
				if (changes.appModel.currentValue != changes.appModel.previousValue){
					if(changes.appModel.currentValue.length == 0){
						if (this.list) {
							this.inputValue = '';
							for(let item of this.list){
								item.selected = false;
							}
						}

					} else {
						this.setChange();
					}

				}
			}
		}

	}

	ngOnInit() {
		// console.log(this.list)
		this.setChange()


	}

}
