import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkerMessage } from '../../../../worker/app-workers/shared/worker-message.model';
import { WORKER_TOPIC } from '../../../../worker/app-workers/shared/worker-topic.constants';
import { GlobalService } from './global.service';
import { environment }from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
	public readonly workerPath = 'assets/workers/main.js';

	workerUpdate$: Observable<WorkerMessage>;
	private worker: Worker;
	private workerSubject: Subject<WorkerMessage>;
	private workerMessageSubscription: Subscription;

	private worker_status = new Subject<any>();
	changeWorker$ = this.worker_status.asObservable();

	constructor(private _gS: GlobalService, private http: HttpClient) {
		this.workerInit();
	}

	doWork(workerMessage: WorkerMessage) {

		if (this.worker) {
			this.worker.postMessage(workerMessage);
		}
	}

	initProcess() {
		if (localStorage.getItem('queueReportClap')) {
			this.doWork({ topic: WORKER_TOPIC.loadProcess, data: localStorage.getItem('queueReportClap') })
		}
	}

	addProcess(process) {
		this.doWork({
			topic: WORKER_TOPIC.addProcess,
			data: process
		})
	}

	deleteProcess(process) {
		this.doWork({
			topic: WORKER_TOPIC.deleteProcess,
			data: process
		})
	}



	workerInit(): void {
		try {
			if (!!this.worker === false) {
				this.worker = new Worker(this.workerPath);
				this.workerSubject = new Subject<WorkerMessage>();
				this.workerUpdate$ = this.workerSubject.asObservable();

				this.workerMessageSubscription = fromEvent(this.worker, 'message')
					.subscribe((response: MessageEvent) => {
						if (this.workerSubject) {
							this.workerSubject.next(WorkerMessage.getInstance(response.data));
						}
					}, (error) => console.error('WORKER ERROR::', error));
			}
		} catch (exception) {
			console.error(exception);
		}
	}

	changeWorker(item: any) {
		this.worker_status.next(item);
	}

	reportService(url, data) {
		let params = {};
		Object.keys(data).map(key => {
			params[key] = data[key]
		})

		return this.http.post(environment.apiUrl+ "/" + url, params)
		.pipe(map((response:Response)=>response));
	}


}
