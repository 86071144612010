import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-input-file',
	templateUrl: './input-file.component.html',
	styleUrls: ['./input-file.component.css'],
	providers: [
	{ 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true
    }]
})
export class InputFileComponent implements OnInit, ControlValueAccessor {

	propagateChange = (_: any) => {};

	@Input() cleanOnSend: boolean = false;
	@Input()  min_input: boolean = false;
	@Input()  extensiones_permitidas: Array<string> = [];
	@Input()  es_multiple: boolean = false;
	@Input()  quantity:number = 12;
	@Input()  prefix_name;
	@Input() calidad_compresion: number = 1024;
	@Output() ArchivosEnviar : EventEmitter<File[]> = new EventEmitter();

	private _listaArchivos :any = [];
	private archivosInvalidos : any = [];
	private listValue:any;


	
	listaImagenes: any = [];
	constructor() { }

	ngOnInit() {
	}



	OnChangeInput(event){
		// console.log(event.target.files);
		this.SeleccionarArchivos(event.target.files)
	}

	SeleccionarArchivos(listaArchivos: Array<File>){
		var archivos = listaArchivos;

		// console.log(archivos)

		for(var archivo of archivos){
			var extension = archivo.name.split('.')[archivo.name.split('.').length - 1];

			if(this.extensiones_permitidas.lastIndexOf(extension) != -1){
				if(this.es_multiple){
					this._listaArchivos.push(archivo)
				}else{
					this._listaArchivos = [archivo]
				}

				Promise.all(Array.prototype.slice.call(this._listaArchivos, 0).map(this.leerArchivoComoObjeto, this))
				.then( respuesta => this.listaImagenes = respuesta);

				if(!this.es_multiple){
					this.EnviarArchivos(this.listaImagenes)
				}
			}else{
				this.archivosInvalidos.push(archivo);
			}
		}

		Promise.all(Array.prototype.slice.call(this._listaArchivos, 0).map(this.leerArchivoComoObjeto, this))
			.then(respuesta => {
				this.listaImagenes = respuesta
				if (this.es_multiple) {
					this.EnviarArchivos(this.listaImagenes)
				}
			});

	}

	EnviarArchivos(event){
		Promise.all(Array.prototype.slice.call(this._listaArchivos).map(this.leerArchivoComoObjeto, this))
		.then(respuesta => {
			var archivos:any = respuesta;
			this.ArchivosEnviar.emit(archivos)
			this.propagateChange(archivos)

			if (this.cleanOnSend) {
				this._listaArchivos = []
			}
		});
	}

	SeleccionarArchivosInvalidos(archivos){
		this.archivosInvalidos = archivos
	}

	leerArchivoComoImagen(archivo: File){
		return new Promise((resolver, rechazar) =>{
			var lector = new FileReader();

			lector.onloadend = (e) => {
				resolver(lector.result)
			};

			lector.onerror = e => rechazar(e);
			lector.readAsDataURL(<Blob>archivo);
		});
	}

	leerArchivoComoObjeto(archivo: File){
		return new Promise((resolver, rechazar) =>{
			var lector = new FileReader();
			lector.onloadend = (e) => {
				archivo['url_data'] = lector.result;
				resolver(archivo)
			};
			lector.onerror = e => rechazar(e);
			lector.readAsDataURL(<Blob>archivo);
		});
	}

	obtenerArchivosValidos(){
		return this.extensiones_permitidas.map(x=> `.${x}`).join(',');
	}

	writeValue(value: any) {
		if (value !== undefined) {
			this.listValue = value;
		}
	}

	registerOnChange(fn) {
    	this.propagateChange = fn;
  	}

  	registerOnTouched() {}

}
