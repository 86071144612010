import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from '../../services/global.service';

@Component({
	selector: 'app-context-comment-side',
	templateUrl: './context-comment-side.component.html',
	styleUrls: ['./context-comment-side.component.css']
})
export class ContextCommentSideComponent implements OnInit {

	@Input() event;
	@Output() notify = new EventEmitter<any>();

	loader: boolean = false;
	canShow: boolean = false;
	form: FormGroup;
	forbiddenClasses: string[] = [
		'header-prev',
		'highlight',
		'container-esc'
	];

	public onSelectedText(ev, allowEmptyText = false) {
		let selection = window.getSelection();
		let text = selection.toString().trim();
		let html = this._gS.getSelectionHtml();

		let nodeTagStart = this._gS.getStartSelectedNode();
		let nodeTagFinish = this._gS.getEndSelectedNode();

		// Validates if selected text is valid (Cannot select a comment)
		const VALID_SELECTION = this.validateSelections(ev, html);
		if ((!text || !VALID_SELECTION) && !allowEmptyText) return;

		// Sets values to form.
		this.canShow = true;
		this.form.patchValue({
			'char_from': nodeTagStart['dataset'].metaIndex,
			'char_to': nodeTagFinish['dataset'].metaIndex
		});

		// Shows window
		let oRange;
		let oRect
		if (!allowEmptyText) {
			oRange = selection.getRangeAt(0); //get the text range
			oRect = oRange.getBoundingClientRect(); //get real position of only selected text.
		}
		const BOX_HALF = 110;
		let positions = {
			clientX: allowEmptyText ? ev.clientX : Math.round((oRect.x + (oRect.x + oRect.width)) / 2) - BOX_HALF,
			clientY: ev.clientY + 10
		}
		this._gS.onRightClick(positions, 9, 250);
	}

	closeMenuTextSelected() {
		this.form.patchValue({
			'comment': '',
			'char_from': '',
			'char_to': ''
		});
		this.form.markAsUntouched();
		this.canShow = false;
		this._gS.hidemenu();
	}

	onSubmit() {
		this.notify.emit(this.form.value);
	}

	buildForm() {
		this.form = this.fb.group({
			'comment': '',
			'char_from': '',
			'char_to': ''
		});
	}

	validateSelections(ev, html: string): boolean {
		// Validates selected HTML
		for (const textClass of this.forbiddenClasses) {
			let includes = html.includes(textClass);
			if (includes) return false;
		}

		// Validates there is no highligh in event path
		if (ev) {
			let pathElements = ev.composedPath();
			for (const elem of pathElements) {
				if (elem.classList) {
					let hasHighlight = elem.classList.contains('highlight');
					if (hasHighlight) return false;
				}
			}
		}

		return true;
	}


	constructor(
		public _gS: GlobalService,
		private fb: FormBuilder) { }

	ngOnInit() {
		this.buildForm();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.event) {
			this.onSelectedText(this.event);
		}
	}

}
