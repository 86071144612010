import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { LoginService } from './../services/login-service.service';
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private _lS: LoginService, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		const loggedIn = this._lS.getIsLoggedIn();
		if (!loggedIn) {
			this._lS.setRedirecUrl(state);
			const url = environment.apiUrl + "/home/index";
			window.location.href = url;
		}
		return loggedIn;
	}
}
