import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: string, argument:string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    
    searchText = searchText.toLowerCase();
        return items.filter( it => {
        	var a = eval('it.' + argument) ? eval('it.' + argument) : '';
          return a.toString().toLowerCase().includes(searchText);
        });
   }

}
