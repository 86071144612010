import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-line-search',
  templateUrl: './line-search.component.html',
  styleUrls: ['./line-search.component.css']
})
export class LineSearchComponent implements OnInit {
  @Output() text: EventEmitter<any> = new EventEmitter();
	info: any = {
		texto: ''
	}

	buscarNombre(){
    this.text.emit(this.info.texto);
	}

	showBuscador(status){
		this.info.texto = '';
		this.buscarNombre();
	}

	ent(event) {
    if(event.which == 13 || event.keyCode == 13) {
      this.buscarNombre();
      return false;
    }
    return true;
	}

	reset(){
		 if(this.info.texto == ''){
    	this.buscarNombre();
    }
	}

  constructor() { }

  ngOnInit() {
  }

}
