import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'ventana-confirmacion',
	templateUrl: './ventana-confirmacion.component.html',
	styleUrls: ['./ventana-confirmacion.component.css']
})
export class VentanaConfirmacionComponent implements OnInit {
	@Output() pasar_valor_pop = new EventEmitter();
	@Input() titulo;
	@Input() descripcion;
	@Input() secondaryDesc;
	@Input() botton_aceptar = 'Aceptar';
	@Input() selected_item;
	@Input() typeOfWindow = "basic" // basic - popup - alert
	@Input() showButtons: boolean = true;
	@Input() popup = "";
	comment = '';

	@Output() OnAccept = new EventEmitter();
	@Output() OnCancel = new EventEmitter();


	constructor() { }

	botonAceptar() {
		if (this.comment) this.selected_item['comment'] = this.comment;
		this.OnAccept.emit(this.selected_item);
	}
	botonPr(){
	}

	botonCancelar() {
		this.selected_item = null;
		this.OnCancel.emit(true);
	}
	close_popUp(ev) {
		this.pasar_valor_pop.emit(ev);
	}

	ngOnInit() {
	}
}
