import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangChangePipe } from '../pipes/lang-change.pipe';
import { SearchPipe } from './search.pipe';
import { MinutesPipe } from './minutes.pipe';
import { HtmlSanitizePipe } from './html-sanitize.pipe';
import { FilterPipe } from './filter.pipe';
import { HoursPipe } from './hours.pipe';
import { NoRepeatPipe } from './no-repeat-list.pipe';
import { HrsMinSec } from './hrsMinSec.pipe';
import { HtmlSanitizeUrlPipe } from './html-sanitize-url.pipe';
import { ObtenerNombreIdPipe } from './obtener-nombre-id.pipe';
import { OrderByPipe } from './order-by.pipe';
import { CeroPipe } from './cero.pipe';
import { SanitizeInnerHtmlPipe } from './sanitize-inner-html.pipe';
import { SortWeeksPipe } from './sort-weeks.pipe';


@NgModule({
	declarations: [
		LangChangePipe,
		SearchPipe,
		MinutesPipe,
		HtmlSanitizePipe,
		FilterPipe,
		HoursPipe,
		NoRepeatPipe,
		HrsMinSec,
		HtmlSanitizeUrlPipe,
		ObtenerNombreIdPipe,
		OrderByPipe,
		CeroPipe,
		SanitizeInnerHtmlPipe,
		SortWeeksPipe
	],
	imports: [
		CommonModule,
	],
	providers: [
		LangChangePipe,
		SearchPipe,
		MinutesPipe,
		HtmlSanitizePipe,
		FilterPipe,
		HoursPipe,
		NoRepeatPipe,
		HrsMinSec,
		HtmlSanitizeUrlPipe,
		OrderByPipe
	],
	exports: [
		LangChangePipe,
		SearchPipe,
		MinutesPipe,
		HtmlSanitizePipe,
		FilterPipe,
		HoursPipe,
		NoRepeatPipe,
		HrsMinSec,
		HtmlSanitizeUrlPipe,
		OrderByPipe,
		CeroPipe,
		SanitizeInnerHtmlPipe,
		SortWeeksPipe
	]
})
export class PipesModule { }
