import { Pipe, PipeTransform } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Translate_ENG } from '../../languages/language_eng';
import { Translate_ESP } from '../../languages/language_esp';
import { LanguageChangeService } from '../services/language-change.service';

@Pipe({
  	name: 'langChange'
})
export class LangChangePipe implements PipeTransform {
	transform(value: any): any {
  	var e:any;
		if (this._languageChangeService.getLenguage() === 'eng'){
      e = Translate_ENG[value]
    } else {
      e = Translate_ESP[value]
    }
    if(e){
    	return e
		} else {
  		return value
		}
	}
	
	constructor( private _languageChangeService: LanguageChangeService){}

}
