import { 
	Directive,
	Input,
	Output,
	EventEmitter,
	HostListener,
	HostBinding,
} from '@angular/core';

@Directive({
	selector: '[appArrastrarNSoltar]'
})
export class ArrastrarNSoltarDirective {

	@Input() extensiones_permitidas: Array<string> = [];
	@Input() private es_multiple : boolean;
	@Output() private CambioArchivoEmtter : EventEmitter<File[]> = new EventEmitter();
	@Output() private ArchivosInvalidosEmitter : EventEmitter<File[]> = new EventEmitter();
	@HostBinding('style.background') private colorFondo = '#fff';
  @HostBinding('style.borderColor') private colorBorde = '#d3d3d3';

	constructor() { }

	@HostListener('dragover', ['$event']) public onDragOver(evt){
  		evt.preventDefault();
    	evt.stopPropagation();
  		this.colorFondo = '#eee';
    } 

  	@HostListener('dragleave', ['$event']) public onDragLeave(evt){
  		evt.preventDefault();
    	evt.stopPropagation();
  		this.colorFondo = '#fff';
  	}

  	@HostListener('drop', ['$event']) public onDrop(evt){
  		evt.preventDefault();
    	evt.stopPropagation();
  		this.colorFondo = '#fff';


  		var archivos = (this.es_multiple) ? evt.dataTransfer.files : [evt.dataTransfer.files[0]];
  		var archivos_validos : Array<File> = [];
    	var archivos_invalidos : Array<File> = [];


    	for(var file of archivos){
			var ext = file.name.split('.')[file.name.split('.').length - 1];
  			if(this.extensiones_permitidas.lastIndexOf(ext) != -1){
  				archivos_validos.push(file);
  			}else{
  				archivos_invalidos.push(file);
          this.colorFondo =  '#f5e5e5';
          this.colorBorde = '#ff7373';
          setTimeout( _ => {
              this.colorFondo =  '#fff';
              this.colorBorde = '#d3d3d3';
          },800)
  			}
    	}

  		this.CambioArchivoEmtter.emit(archivos_validos);
  		this.ArchivosInvalidosEmitter.emit(archivos_invalidos);
  	}

}
