import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';


import { SharedRoutingModule } from './shared-routing.module';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { PipesModule } from './pipes/pipes.module';
import { TablaGeneralComponent } from './tabla-general/tabla-general.component';
import { InputFileComponent } from './input-file/input-file.component';
import { ArrastrarNSoltarDirective } from './directivas/arrastrar-nsoltar.directive';
import { MinValidatorDirective, MaxValidatorDirective } from './directivas/min-max-validator.directive';
import { NumberOnlyDirective } from './directivas/number-only.directive';
import { SelectsearchComponent } from './selectsearch/selectsearch.component';
import { AutoFocusDirective } from './directivas/auto-focus.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent } from './dropdown/dropdown.component';
import { PageInputComponent } from './page-input/page-input.component';
import { MinutesInputComponent } from './minutes-input/minutes-input.component';
import { TableSelectControlComponent } from './table-select-control/table-select-control.component';

import { VentanaConfirmacionComponent } from './ventana-confirmacion/ventana-confirmacion.component';
import { RatioImagenDirective } from './directivas/ratio-imagen.directive';
import { SelectSearchComponent } from './select-search/select-search.component';
import { TableItemsComponent } from './table-items/table-items.component';
import { PreviewEscenaComponent } from './preview-escena/preview-escena.component';
import { TablaParametrosDosComponent } from './tabla-parametros-dos/tabla-parametros-dos.component';
import { LineSearchComponent } from './line-search/line-search.component';
import { HoursInputComponent } from './hours-input/hours-input.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TablaClips2Component } from './tabla-clips2/tabla-clips2.component';
import { HideColumnsComponent } from './hide-columns/hide-columns.component';
import { MinDatesValidatorDirective, MaxDatesValidatorDirective } from './directivas/min-max-dates-validator.directive';
import { WorkerWindowComponent } from './worker-window/worker-window.component';
import { ReportsWindowComponent } from './worker-window/reports-window/reports-window.component';
import { SpaceToListJoinPipe } from './pipes/space-to-list-join.pipe';
import { ImageLoaderDirective } from './directivas/image-loader.directive';
import { ContextCommentSideComponent } from './preview-escena/context-comment-side/context-comment-side.component';
import { GraficoComponent } from './grafico/grafico.component';
import { GraficoIndicadorCircularComponent } from './grafico/grafico-indicador-circular/grafico-indicador-circular.component';
import { GraficoSeccionComponent } from './grafico/grafico-seccion/grafico-seccion.component'
import { NumericDirective } from './directivas/numeric.directive';

@NgModule({
	declarations: [
		MenuComponent,
		FooterComponent,
		TablaGeneralComponent,
		InputFileComponent,
		ArrastrarNSoltarDirective,
		MinValidatorDirective,
		MaxValidatorDirective,
		NumberOnlyDirective,
		SelectsearchComponent,
		DropdownComponent,
		PageInputComponent,
		MinutesInputComponent,
		TableSelectControlComponent,
		VentanaConfirmacionComponent,
		RatioImagenDirective,
		SelectSearchComponent,
		TableItemsComponent,
		PreviewEscenaComponent,
		TablaParametrosDosComponent,
		LineSearchComponent,
		HoursInputComponent,
		TablaClips2Component,
		HideColumnsComponent,
		MinDatesValidatorDirective,
		MaxDatesValidatorDirective,
		AutoFocusDirective,
		WorkerWindowComponent,
		ReportsWindowComponent,
		SpaceToListJoinPipe,
		ImageLoaderDirective,
		ContextCommentSideComponent,
		GraficoComponent,
		GraficoIndicadorCircularComponent,
		GraficoSeccionComponent,
		NumericDirective,
	],
	imports: [
		CommonModule,
		SharedRoutingModule,
		PipesModule,
		FormsModule,
		ReactiveFormsModule,
		HttpModule,
		HttpClientModule,
		InfiniteScrollModule,
		CurrencyMaskModule,
		Ng2GoogleChartsModule
	],
	providers: [
		DatePipe,
		DecimalPipe,
		WorkerWindowComponent,
		SpaceToListJoinPipe
	],
	exports: [
		MenuComponent,
		FooterComponent,
		PipesModule,
		TablaGeneralComponent,
		InputFileComponent,
		SelectsearchComponent,
		DropdownComponent,
		PageInputComponent,
		MinutesInputComponent,
		TableSelectControlComponent,
		VentanaConfirmacionComponent,
		RatioImagenDirective,
		MinValidatorDirective,
		MaxValidatorDirective,
		NumberOnlyDirective,
		SelectSearchComponent,
		TableItemsComponent,
		PreviewEscenaComponent,
		TablaParametrosDosComponent,
		LineSearchComponent,
		HoursInputComponent,
		TablaClips2Component,
		HideColumnsComponent,
		MinDatesValidatorDirective,
		MaxDatesValidatorDirective,
		AutoFocusDirective,
		WorkerWindowComponent,
		SpaceToListJoinPipe,
		ImageLoaderDirective,
		GraficoSeccionComponent,
		NumericDirective
	]
})
export class SharedModule {

}
