import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortWeeks'
})
export class SortWeeksPipe implements PipeTransform {

  transform(value: any[], pre: boolean): any {
    if (value.length > 0) {
      if (pre) {
        value = value.filter(wee => wee.number);
      }
      else {
        value = value.filter(wee => !wee.number);
      }
      return value;
    }
    else return [];
  }
}
