import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-table-items',
	templateUrl: './table-items.component.html',
	styleUrls: ['./table-items.component.css']
})
export class TableItemsComponent implements OnInit {

	@Input() titulo;
	@Input() puede_borrar;
	@Input() titulo_totales;
	@Input() datos_tabla;
	@Output() OnOrder = new EventEmitter();
	@Output() OnDoubleClick = new EventEmitter();
	
	orden_seleccionado;
	order_datos_tabla;

	constructor() { }

	ngOnInit() {
		this.ordenar();
	}


	ngOnChanges(changes){
		if(!changes.datos_tabla.firstChange){
			this.datos_tabla = changes.datos_tabla.currentValue;
			this.order_datos_tabla = this.datos_tabla.sort((a, b) => parseInt(a.order) - parseInt(b.order));
			this.ordenar();
		}
	}

	doubleClick(fila){
		this.OnDoubleClick.emit(fila);
	}

	ordenar(event = null, fila = null){

		let numero_orden = Number(this.orden_seleccionado);

		if(fila){
			fila.order = fila.order > numero_orden  ? fila.order + 1 : fila.order - 1; 	
		}

		
		this.order_datos_tabla = this.datos_tabla
										.sort((a, b) => parseInt(a.order) - parseInt(b.order))
										.map( (x, i) => {
											x.order = i + 1;
											return x;
										});
		
		this.OnOrder.emit(this.order_datos_tabla);
	}

}
