import { Component, OnInit, Input, ElementRef, ViewChild, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import html2canvas from 'html2canvas';


@Component({
	selector: 'app-grafico-indicador-circular',
	templateUrl: './grafico-indicador-circular.component.html',
	styleUrls: ['./grafico-indicador-circular.component.css']
})
export class GraficoIndicadorCircularComponent implements OnInit {
	
	@ViewChild('piegraph', {static: true}) divToMeasureElement: ElementRef;
	@Input() list: any;
	@Input() total: number;
	@Input() dataType: string;
	@Input() selectedIndex: number = 1;
	@Input() height: number = 300;
	@Input() showLegend: boolean = false;
	@Input() numberLegends: number;
	readonly MAX_HEIGHT = 170;
	readonly MIN_HEIGHT = 120;
	selectedValue: any = [];
	baseRadius: number = 110;
	viewBoxwidth: number = 300;
	viewBoxheight: number = 300;
	viewBoxSize: string = "0 0 300 300";
	hideLegend: boolean = false;
	
	getlineSpace(ev, index) {
		let radius = this.getRadius(index);
		let circumference = 2 * Math.PI * radius;
		return `${(ev / this.total * circumference)}, ${circumference}`;
	}
	
	getRadius(index) {
		let difference = this.list.length - (index + 1);
		return this.baseRadius - (12 * difference);
	}
	
	checkRotation(ev) {
		if (this.dataType == 'Percent') {
			var pos = 0
			var a = -90
			for (let line of this.list) {
				if (pos < ev) {
					var rot = line.value / this.total * (-360);
					a -= rot;
				}
				pos++;
			}
			var p = `rotate(${a} ${this.viewBoxwidth / 2} ${this.viewBoxheight / 2})`;
			return p
			
		} else if (this.dataType == 'PercentSpecial') {
			var a = -90
			if (ev == 0) {
				var rot = this.list[1].value / this.total * (-360);
				a -= rot;
			}
			var p = `rotate(${a} ${this.viewBoxwidth / 2} ${this.viewBoxheight / 2})`;
			return p
		} else {
			return `rotate(-90 ${this.viewBoxwidth / 2} ${this.viewBoxheight / 2})`;
		}
	}
	
	showItem(pos) {
		this.selectedValue = this.list[pos]
	}
	
	startGraph() {
		this.selectedValue = this.list[this.selectedIndex]
	}
	
	getPercent(item) {
		let number = Math.round((item.value * 100) / this.total);
		return number ? number : 0;
	}
	
	getViewBoxHeight() {
		this.hideLegend = false;
		if (this.showLegend) {
			const LEGEND_ITEM_HEIGHT = 14;
			let gap = this.numberLegends ? this.numberLegends : this.list.length * LEGEND_ITEM_HEIGHT;
			let currentHeight = this.height - gap;
			if (currentHeight < this.MIN_HEIGHT) {
				currentHeight = currentHeight < this.MIN_HEIGHT ? this.MIN_HEIGHT : currentHeight;
				this.hideLegend = true;
			}
			this.viewBoxheight = this.MAX_HEIGHT < currentHeight ? this.MAX_HEIGHT : currentHeight;
			return;
		}
		this.viewBoxheight = this.MAX_HEIGHT < this.height ? this.MAX_HEIGHT : this.height;
	}
	
	setViewBoxSizes() {
		setTimeout(() => {
			this.viewBoxwidth = this.divToMeasureElement.nativeElement.offsetWidth;
			this.getViewBoxHeight();
			this.viewBoxSize = "0 0 " + this.viewBoxwidth + " " + this.viewBoxheight;
			this.baseRadius = (this.viewBoxheight / 2) - 10;
			this._cD.detectChanges();
		}, 0)
	}
	
	constructor(private _cD: ChangeDetectorRef) { }
	
	ngOnChanges(changes: SimpleChanges) {
		if (changes.height && this.divToMeasureElement) {
			this.setViewBoxSizes();
		}
	}
	
	ngOnInit() {
		this.selectedValue = this.list[this.selectedIndex];
	}
	
	ngAfterViewInit() {
		this.setViewBoxSizes();
	}
	
	public exportar() {

		return html2canvas(this.divToMeasureElement.nativeElement)
		// 	.then(canvas => {
		// 	console.log(canvas)
		// 	const a = document.createElement('a');
      	// 	a.href = canvas.toDataURL('image/png');
      	// 	a.download = `grafica.png`
      	// 	a.click();
		// })
	}
	
}
