import { Component, OnInit, forwardRef, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	FormControl,
	Validator
} from '@angular/forms';
import { FilterService } from '../services/filter.service';

@Component({
	selector: 'select-search',
	templateUrl: './select-search.component.html',
	styleUrls: ['./select-search.component.css'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectSearchComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectSearchComponent), multi: true }
	],
})
export class SelectSearchComponent implements ControlValueAccessor, Validator, OnChanges {

	private jsonString: string;
	private parseError: boolean;
	private data: any;
	public filteredList: any = []
	private filteredPaged: any = []
	showOptions: boolean = false;
	text_selected: string = "";
	buscar_elementos: string;
	name_prove: string = "";

	pager_size = 15;
	page = 0;

	sliceStart = 0;
	sliceEnd = 15;

	@Input() name;
	@Input() placeholder;
	@Input() disabled = false;
	@Input() requerido = false;
	@Input() propagateWhenEmpty = true; // flag para decidir si emite cambio incluso si es vacio.
	@Input() options;
	@Input() multiple = false;
	@Input() hidden = '';
	@Input() hidden_except = null;
	@Input('toCreate') allowCreate: boolean = false;
	@Input('itemName') item_name;
	@Input('itemId') item_id = 'id';
	@Input() showCheckboxOptions: boolean = false;
	@Input() preferences?= {
		uppercase: true,
		orderList: false,
		order: 'DESC'
	}
	@Input() switch: any = null;
	@Input() height: number = null;

	@Output() estado: EventEmitter<any> = new EventEmitter();
	@Output() selected: EventEmitter<any> = new EventEmitter();
	constructor(private _fS: FilterService, private _cD: ChangeDetectorRef) { }

	writeValue(obj: any) {
		if (obj) {
			this.data = obj;
			if (!isNaN(obj)) {
				if (this.multiple) {
					this.configMultipleCheckbox(obj);
					return;
				}
				this.text_selected = this.selectOption(obj);
			} else {
				if (this.multiple) {
					this.configMultipleCheckbox(obj);
				} else {
					if (obj.value) {
						if (!isNaN(obj.value)) {
							this.text_selected = this.selectOption(obj.value);
						} else {
							this.text_selected = obj.value;
						}
					}
				}
			}
		} else {
			this.text_selected = '';
			this.data = null;
		}
		this._cD.detectChanges();
	}

	configMultipleCheckbox(obj) {
		// Multiple checkbox options
		if (this.showCheckboxOptions) {
			this.options = this.options.map(x => {
				let foundItem = this.data.find(item => item[this.item_id] == x[this.item_id]);
				x.isExcluded = foundItem ? foundItem.isExcluded : false;
				x.selected = foundItem ? foundItem.selected : false;
				return x;
			})
			this.text_selected = this.data.map(x => x[this.item_name]).join(', ');
			return;
		}

		// Normal multiple
		this.options = this.options.map(x => {
			x.selected = false;
			return x;
		})
		this.data = this.options.filter(x => obj.indexOf(x[this.item_id]) > -1).map(x => {
			x.selected = true;
			return x;
		});
		this.text_selected = obj.map(x => this.selectOption(x)).join(', ');
	}

	ngOnChanges(changes) {
		if (changes.options) {
			if (changes.options.previousValue && changes.options.previousValue.length > 0) {
				let options = changes.options.currentValue;
				if (this.multiple && this.data) {
					options.map(x => {
						if (this.showCheckboxOptions) { // multiple checkbox options
							let foundItem = this.data.find(item => item[this.item_id] == x[this.item_id]);
							x.isExcluded = foundItem ? foundItem.isExcluded : false;
							x.selected = foundItem ? foundItem.selected : false;
							return x;
						}
						// normal multiple
						x.selected = false;
						x.selected = this.data.filter(y => x[this.item_id] == y[this.item_id]).length > 0;
						return x;
					})
					this.options = options;
				}
			}
			if (changes.options.currentValue) {
				if (!this.multiple) {
					this.options = changes.options.currentValue;
					this.filteredList = this.options;
				}
			}
		}
		if (changes.preferences || changes.options) {
			if (this.preferences.orderList) {
				this.filteredList = this._fS.sortByArgument(this.options, this.item_name, this.preferences.order);
			}
		}
	}

	onShowOptions() {
		this.showOptions = true;
		this.filteredList = this.options;
		this.filteredPaged = this.filteredList.slice(0, this.pager_size);
	}

	selectOption(value) {
		if (this.item_name) {
			let option = this.options.find(x => x[this.item_id] == value);
			if (option) {
				return option[this.item_name];
			}
		}
	}

	moverPagina(direccion) {
		if (direccion == 'atras') {
			this.sliceStart -= this.pager_size;
			this.sliceEnd -= this.pager_size;
		} else if (direccion == 'adelante') {
			this.sliceStart += this.pager_size;
			this.sliceEnd += this.pager_size;
		}
	}

	selectSingleOption(item) {
		this.selected.emit(item)
		this.data = item;
		this.text_selected = item[this.item_name];
		this.showOptions = false;
		this.propagateChange(this.allowCreate ? { value: this.data[this.item_id], isNew: false } : this.data[this.item_id]);
	}

	selectMultipleOption(item, selected) {
		this.data = this.data || [];
		item.selected = selected;

		this.data = this.options.filter(x => x.selected)
		this.text_selected = this.data.map(x => x[this.item_name]).join(', ');
		let index = this.options.findIndex(x => x[this.item_id] == item[this.item_id]);
		this.options[index] = item;
		// emits different when with/without option
		if (this.showCheckboxOptions) {
			this.propagateChange(this.allowCreate ? { value: this.data, isNew: false } : this.data);
			return;
		}
		// normal emit
		this.propagateChange(this.allowCreate ? { value: this.data.map(x => x[this.item_id]), isNew: false } : this.data.map(x => x[this.item_id]));
	}

	filtrarLista() {
		this.filteredList = this.options;
		if (this.multiple) {
			if (this.buscar_elementos && this.buscar_elementos.length > 0) {
				this.filteredList = this.options.filter(x => {
					if (x[this.item_name] == null) return false;
					return this.adjustText(x[this.item_name]).indexOf(this.adjustText(this.buscar_elementos)) > -1;
				});
			}
		} else {
			if (this.text_selected && this.text_selected.length > 0) {
				this.filteredList = this.options.filter(x => {
					if (x[this.item_name] == null) return false;
					return this.adjustText(x[this.item_name], true).indexOf(this.adjustText(this.text_selected, true)) > -1
				});

				if (!this.allowCreate && this.filteredList.length > 0) {
					let current = this.filteredList.find(x => {
						if (x[this.item_name] == null) return false;
						return this.adjustText(x[this.item_name], true) == this.adjustText(this.text_selected, true);
					})
					if (current) {
						this.data = current[this.item_id];
						this.propagateChange(Number(current[this.item_id]));
					}
				}

				if (this.allowCreate) {
					let current = this.filteredList.find(x => {
						if (x[this.item_name] == null) return false;
						return this.adjustText(x[this.item_name], true) == this.adjustText(this.text_selected, true);
					})

					if (current) {
						this.data = current[this.item_id];
						this.propagateChange(Number(current[this.item_id]));
					} else {
						this.data = this.text_selected;
						this.propagateChange({ value: this.text_selected, isNew: true });
					}
				}

			} else if (this.propagateWhenEmpty) {
				// Si se indica propagateWhenEmpty, propaga valor aun cuando es vacio, útil para validar formularios estrictamente.
				this.propagateChange(null);
			}
		}
	}

	onBlur() {
		if (!this.allowCreate && !this.multiple) {
			this.text_selected = this.text_selected ? this.text_selected : "";
			let current = this.filteredList.find(x => this.adjustText(x[this.item_name], true) == this.adjustText(this.text_selected, true));
			
			if (!current) {
				this.text_selected = '';
				this.data = null;

				setTimeout(_ => {
					if (!this.data) {
						this.propagateChange(null);		
					}
				}, 100)
				
			}
		}
	}

	valid(text) {
		if (text.length >= 0) {
			this.estado.emit(text);
		} else {
			this.estado.emit('');
		}
	}

	onClickedOutside() {
		this.showOptions = false;
	}

	toggleWithoutOption(event, option) {
		event.stopPropagation();
		option.isExcluded = !option.isExcluded;
		this.selectMultipleOption(option, option.selected);
	}

	adjustText(text: string, toString: boolean = false){
		if(toString) text = text.toString();
		return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}

	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	public registerOnTouched(fn: any) { }


	public validate(c: FormControl) {
		if (this.requerido) {
			return (this.data) ? null : {
				jsonParseError: {
					valid: false,
				},
			};
		}
		return null;
	}

	private propagateChange = (_: any) => {};

}
