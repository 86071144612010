export const Translate_ESP = {
	"Home":"Inicio",
	"Productions":"Producciones",
	"Finances":"Finanzas",
	"Admin":"Admin",
	"All rights reserved.":"Todos los derechos reservados.",
	"Create Production":"Crear Producción",
	'Edit Production':"Editar Producción",
	"Name":"Nombre",
	"Season":"Temporada",
	"Format":"Formato",
	"Genre":"Género",
	"Chapters":"Capítulos",
	"Pg x Ch":"Pág x Cap",
	"Sce x Ch":"Esc x Cap",
	"Min x Ch":"Min x Cap",
	"Units":"Unidades",
	"Wks. Pre-prod":"Sem. Pre-prod",
	"Wks. prod":"Sem. prod",
	"Wks. post":"Sem. post",
	"Pre-production start":"Incio pre-producción",
	"Production start":"Incio producción",
	"Production end":"Fin produccción",
	"Post-production end":"Fin post-produccción",
	"Cancel":"Cancelar",
	"Create":"Crear",
	"W":"S",
	"TO":"A",
	"PRE - PRODUCTION:":"PRE - PRODUCCIÓN:",
	"PRODUCTION:":"PRODUCCIÓN:",
	"POST - PRODUCTION:":"POST - PRODUCCIÓN:",
	"ON AIR":"AIRE",
	"Remove":"Eliminar",
	"Start date":"Fecha inicio",
	"End date":"Fecha fin",
	"Unit":"Unidad",
	"Filter":"Filtrar",
	"Filter Production":"Filtrar Producción",
	"Status":"Estado",
	"Pre-production start from":"Incio pre-producción desde",
	"Pre-production start to":"Incio pre-producción hasta",
	"Production start from":"Incio producción desde",
	"Production start to":"Incio producción hasta",
	"Post-production start from":"Incio post-producción desde",
	"Post-production start to":"Incio post-producción hasta",
	"Edit":"Editar",
	"Save":"Guardar",
	"Filter Scenes":"Filtrar Escenas",
	"Insert":"Insertar",
	"Chapter from":"Capítulo desde",
	"Chapter to":"Capítulo hasta",
	"Cont. day from":"Día cont desde",
	"Cont. day to":"Día cont hasta",
	"Location / Studio":"Locación / Estudio",
	"Inside / Outside":"Interior / Exterior",
	"Day / Night":"Día / Noche",
	"Date from":"Fecha desde",
	"Date to":"Fecha hasta",
	"Locations":"Locaciones",
	"Sets":"Sets",
	"Characters":"Personajes",
	"Only selected characters":"Solo personajes seleccionados",
	"At least one of the selected characters":"Por lo menos alguno de los personajes seleccionados",
	"Flashback":"Flashback",
	"Location shots":"Tomas de ubicación",
	"Added scenes":"Hijuelas",
	"(Multiple selection with Ctrl or Cmd)":"(Selección múltiple con Ctrl o Cmd)",
	"Select":"Seleccione",
	"Item Category":"Categoría elementos",
	"Elements":"Elementos",
	"Scenes":"Escenas",
	"Chapter":"Capítulo",
	"Scene":"Escena",
	"Day":"Día",
	"Year":"Año",
	"Pages":"Páginas",
	"Estimated. Min":"Min. estimados",
	"Produced. Min":"Min. producidos",
	"Non-effective. Min":"Min. no efectivos",
	"Location":"Locación",
	"Main character":"Pers. principal",
	"Secondary character":"Pers. secundario",
	"Extra character":"Pers. extra",
	"Dub character":"Pers. doble",
	"Daily Plan":"Plan diario",
	"Reports":"Reportes",
	"Dashboard":"Dashboard",
	"Production Plan":"Plan Producción",
	"Production Calendar":"Calendario Producción",
	"Production Milestones":"Hitos Producción",
	"It does not show results":"No muestra resultados",
	"Create Scenes":"Crear Escenas",
	"Edit Scenes":"Editar Escenas",
	"Scene number":"Número escena",
	"Day history":"Historia del día",
	"Page":"Página",
	"#":"#",
	"Set":"Set",
	"There are no options to list":"No hay opciones para listar",
	"Next":"Siguiente",
	"Previous":"Anterior",
	"Added scene":"Hijuela",
	"Establishing shoot":"Toma de ubicación",
	"Produced":"Producida manual",
	"Description":"Descripción",
	"Insert text":"Insertar texto",
	"Event":"Evento",
	"Events":"Eventos",
	"Null":"Nulo",
	"Mini event":"Mini evento",
	"Character role":"Papel del personaje",
	"Character":"Personaje",
	"Qty":"Cant",
	"Quantity":"Cantidad",
	"Click to add new character":"Clic para agregar un nuevo personaje",
	"Edit Character":"Editar Personaje",
	"Add Character":"Crear Personaje",
	"Character's name": "Nombre del personaje",
	"Scenes status":"Estados escenas",
	"Include scenes in development":"Incluir escenas en desarrollo",
	"Scenes in development included":"Escenas en desarrollo incluidas"
}



