import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ElementRef, ViewChild } from '@angular/core';
import { GlobalService } from '../../shared/services/global.service';

@Component({
	selector: 'app-hours-input',
	templateUrl: './hours-input.component.html',
	styleUrls: ['./hours-input.component.css']
})
export class HoursInputComponent implements OnInit {
	@Input() appModel: any;
	@Input() requerido: any;
	@Input() autoFocus: boolean = false;
	@Input() canResetHour: boolean = false;
	@Output() appModelChange = new EventEmitter<string>();
	llamado: any = {
		hora: null,
		minutos: '',
		horario: ''
	}
	salida: any;

	actualizar() {
		if (this.llamado.hora == 'HH' && this.canResetHour) {
			this.resetHour();
			return;
		}

		if (Number(this.llamado.minutos) > 59) {
			this.llamado.minutos = '59';
		}
		if (isNaN(Number(this.llamado.minutos)) || Number(this.llamado.minutos) == 0) {
			this.llamado.minutos = '00';
		}
		this.guardarFormato();
	}

	blurrr() {
		// console.log('this.llamado.minutos',this.llamado.minutos, Number(this.llamado.minutos));
		if (isNaN(Number(this.llamado.minutos)) || Number(this.llamado.minutos) == 0) {
			this.llamado.minutos = '00';
		}
		if (Number(this.llamado.minutos) < 10 && Number(this.llamado.minutos) != 0) {
			if (this.llamado.minutos.length < 2) {
				this.llamado.minutos = '0' + this.llamado.minutos;
			}
			// else{
			// 	this.llamado.minutos = this.llamado.minutos;
			// }
		}
		this.guardarFormato();
	}

	guardarFormato() {
		this.salida = this._gS.formatoHora12a24(this.llamado.hora, this.llamado.minutos, this.llamado.horario);
		this.appModelChange.emit(this.salida);
	}

	setValues(ev) {
		var x = ev.split(' ');
		var y = x[0].split(':');
		for (let z of this._gS.horas) {
			if (z.hora == y[0]) {
				this.llamado.hora = z.value;
			}
		}
		this.llamado.minutos = y[1] == '--' ? null : y[1];
		this.llamado.horario = x[1] ? x[1] : this.getHorarioIfNotExist(y[0]);
	}

	getHorarioIfNotExist(horaMilitar) {
		return horaMilitar >= 12 ? 'PM' : 'AM'
	}

	resetHour() {
		this.llamado.hora = null;
		this.llamado.minutos = '';
		this.llamado.horario = '';
		this.salida = '--:-- --';
		this.appModelChange.emit(this.salida);
	}

	constructor(public _gS: GlobalService, private elem: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.appModel) {
			this.setValues(changes.appModel.currentValue)
		}
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		if (this.autoFocus) {
			let element = this.elem.nativeElement.querySelector('.hour');
			element.focus();
		}
	}

}
