import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkerMessage } from '../../../../worker/app-workers/shared/worker-message.model';
import { WORKER_TOPIC } from '../../../../worker/app-workers/shared/worker-topic.constants';
import { WorkerService } from '../services/worker.service';
import { environment }from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import { GlobalService } from '../services/global.service';
import { LoginService } from '../services/login-service.service';

@Component({
  selector: 'app-worker-window',
  templateUrl: './worker-window.component.html',
  styleUrls: ['./worker-window.component.css']
})
export class WorkerWindowComponent implements OnInit {

  list_process:any = [];
  workerTopic: string;
  workerServiceSubscription: Subscription;
  currentWorkerMessage: WorkerMessage;
  workerResponse:string;
  subs: Subscription[] = [];
  tokenIsValid: boolean = false;
  view: string = 'home';
  pendingFilesStatus: string;

  constructor(private worker: WorkerService, private _gS: GlobalService, private _lS: LoginService) { }

  ngOnInit() {
    const workerMessage = new WorkerMessage(WORKER_TOPIC.initProcess, {API_URL: environment.apiUrl, token: this._lS.getSessionId()});
    this.listenForWorkerResponse();
    this.worker.doWork(workerMessage);
    this.worker.initProcess();
    this.worker.changeWorker$.subscribe( data => {
      this.worker.addProcess(data);
    })
  }

  ngOnDestroy(): void {
    if (this.workerServiceSubscription) {
      this.workerServiceSubscription.unsubscribe();
    }
    this.subs.map(s => s.unsubscribe());
  }

  getProcess(){
    if(localStorage.getItem('queueReportClap')){
      let data = JSON.parse(localStorage.getItem('queueReportClap'))
      this.list_process = data ? data : null;
    }
  }

  deleteProcess(event, process){
    event.stopPropagation()
    event.preventDefault()
    this.worker.deleteProcess(process);
  }

  downloadReport(event, file){
    if(file.isFrontReport){
      event.stopPropagation();
      event.preventDefault();
      FileSaver.saveAs(file.info.data, file.fileName);
    }
  }

  changeView(ev){
    this.view = ev;
  }

  getStatusReports(){
    if(this.list_process.length){
      let hasError = false;
      for (const proccess of this.list_process) {
        if(proccess.status == 'failed'){
          hasError = true;
        }
        else if(proccess.status != 'complete'){
          return 'pending';
        }
      }
      return hasError ? 'failed' : 'complete';
    }
    return null;
  }

  private listenForWorkerResponse() {
    this.workerServiceSubscription = this.worker.workerUpdate$
    .subscribe(data => this.workerResponseParser(data));
  }

  private workerResponseParser(message: WorkerMessage) {

    console.log("Messaje:", message)

    switch (message.topic) {
      case  WORKER_TOPIC.initProcess:
        console.log('API init with:' + message.data.API_URL)
        break;

      case  WORKER_TOPIC.addProcess:
      case  WORKER_TOPIC.deleteProcess:
        localStorage.setItem('queueReportClap', JSON.stringify(message.data.queue))
        this.getProcess();
        console.log('Add / Delete process:', message.data.queue)
        break;

      case WORKER_TOPIC.getStatusReport:
        localStorage.setItem('queueReportClap', JSON.stringify(message.data.queue))
        this.getProcess();
        console.log('Status process:' + message.data.queue)
        break;

      default:
        // code...
        break;
    }

  }

}
