import { Component, Input, OnInit } from '@angular/core';
import { Grafica } from 'src/app/producciones/dashboard/graficas';

@Component({
  selector: 'app-grafico-seccion',
  templateUrl: './grafico-seccion.component.html',
  styleUrls: ['./grafico-seccion.component.css']
})
export class GraficoSeccionComponent implements OnInit {

  @Input() graficas;
  graficaActiva: number = 0
  direccionGrafica = {'anterior': false, 'siguiente': true}

  constructor() { }

  ngOnInit() {
  }

  camibarGrafica(direccion, grafica) {

    const totalGraficas = this.graficas.graficas.length - 1;

    if (direccion == 'siguiente') {
      if (this.graficaActiva < totalGraficas) {
        this.graficaActiva++;  
      }
      
    }

    if (direccion == 'anterior') {
      if(this.graficaActiva > 0){
        this.graficaActiva--;
      }
      
    }

    this.direccionGrafica = {'anterior': this.graficaActiva > 0, 'siguiente': this.graficaActiva < totalGraficas}
  }

}
