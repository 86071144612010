import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    value = Math.floor(value / 60);
		const minutes: number = Math.floor(value / 60);
		var a = Number(this.pad(minutes, 2));
		var b = Number(this.pad((value - minutes * 60), 2));
		if(a == 0 && b == 0 || (isNaN(a) || isNaN(b))){
			return '-- : --';
		}else{
			return this.pad(minutes, 2) + ':' + this.pad((value - minutes * 60), 2);
		}
	}

	pad(num:number, size:number): string {
		let s = num+"";
		while (s.length < size) s = "0" + s;
		return s;
	}

}
