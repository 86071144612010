import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hrsMinSec'
})
export class HrsMinSec implements PipeTransform {

	transform(seconds): any {
		let hour: any = Math.floor(seconds / 3600);
		hour = (hour < 10) ? '0' + hour : hour;
		let minute: any = Math.floor((seconds / 60) % 60);
		minute = (minute < 10) ? '0' + minute : minute;
		let second: any = seconds % 60;
		second = (second < 10) ? '0' + second : second;
		return hour + ':' + minute + ':' + second;
	}

}
