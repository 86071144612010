import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, forwardRef} from '@angular/core';
import { LanguageChangeService } from '../services/language-change.service';
import { GlobalService } from '../services/global.service';
import { LangChangePipe } from '../pipes/lang-change.pipe';
import { CookieService } from 'ngx-cookie';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-selectsearch',
  templateUrl: './selectsearch.component.html',
  styleUrls: ['./selectsearch.component.css'],
  providers: [
	{ 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectsearchComponent),
      multi: true
    }]
})
export class SelectsearchComponent implements OnInit, OnChanges{
	
	propagateChange = (_: any) => {};

	@Input() name:string;
	@Input() placeHolder:string;
	@Input() list:any;
	@Input() translateOptions:any;
	@Input() showValue:any;
	@Input() optionValue:string;
	@Input() special:string;
	@Input() required:boolean
	@Input() selectedItem:any;
	@Input() unselctable:any;
	@Input() backTranslate:boolean;
	@Input() allOptions:any;
	@Input() popUp:any;
	@Input() disabled:boolean;
  	@Input() allowCreate:boolean;
	@Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

	filteredList:any;
	selectedOption:any;
	searchText:string;
	showOptions=false;

	respondeToParent(){
		this.notify.emit(eval("this.selectedOption." + this.optionValue));
	}

	selectOption(ev, change=false){
		this.selectedOption = ev;
		this.setValueToShow();
		this.filteredList = [];
		this.showOptions = false;

		

    	if(!change)
		  this.respondeToParent()
	}

	
	setValueToShow(){
		if(this.selectedOption){
			var a = this.getName(this.selectedOption)
      if (a.toString().length > 0){
				this.searchText = "" + a.toString();
			} else {
				this.searchText = ""
			}

		} else {
			this.searchText = ""
		}

		this.propagateChange(this.searchText)

	}
	onFocusMethod(){
		this.searchText = ""
		this.showOptions = true;
		this.filteredList = this.list
	}
	onBlurMethod(){
		setTimeout(() => {
			if (this.filteredList.length > 0 && this.searchText != "" && this.selectedOption){
				this.selectedOption = this._gS.findFilter(this.list,this.searchText,this.showValue[0]);
        // console.log(this.selectedOption)
				this.respondeToParent();
			} else {
				if (this.selectedOption){
          if(this.allowCreate && this.searchText.length > 0){
              this.selectedOption = { [this.showValue[0]]: this.searchText};
              this.respondeToParent();
          }else{
            this.setValueToShow()
          }

				} else{
          // console.log(this.searchText)
          if(this.allowCreate && this.searchText.length > 0){
              this.selectedOption = { [this.showValue[0]]: this.searchText};
              this.respondeToParent();
          }else{
            this.searchText = ''
          }

				}
			}

			this.showOptions = false;
			this.filteredList = [];
		}, 200);
	}
	search(ev){
		var a = this.showValue[0]
		if(this.backTranslate){
			a = this.showValue[0].substring(0, this.showValue[0].length-3);
			a = a + this._languageChangeService.getLenguage();
		}
		this.filteredList = this._gS.searchFilter(this.list,ev,a)
	}
	getName(ev:any){
		// this.setTranslateValue();
		// var a= "";
		// var pos = 0
		// for(let value of this.showValue){
		// 	var b = ""
		// 	if(eval('ev.' + this.showValue[pos])){
		// 		b =  eval('ev.' + this.showValue[pos]) + " "
		// 	}

		// 	a += b;
		// 	pos ++;
		// }
		// return a
		if(!this.backTranslate){
			return this._langChangePipe.transform(ev[this.showValue[0]])
		} else {
			var str = this.showValue[0].substring(0, this.showValue[0].length-3);
			str = str + this._languageChangeService.getLenguage();
			return eval('ev.' + str)
		}
	}
	setDefaultData(){
		for(let item of this.list){
			var a = eval('item.' + this.optionValue)
			if(a == this.selectedItem){
				this.selectOption(item, true)
			}
		}

	}
	setAll(){
		if(this.allOptions){
			this.list.unshift(this.allOptions)
		}
	}
	ngOnChanges(changes: SimpleChanges){
		if(!this.selectedItem){
			this.selectedOption = null
			// this.selectOption(this.selectedItem);
			// this.setValueToShow()
		}
		if(this.searchText && this.selectedItem == null){
			this.searchText = ''
		}

		if(this.list &&   this.list.length > 0 && (this.selectedItem || this.selectedItem == 0)){
			this.setTranslateValue();
			this.setDefaultData();
		}

	}
	setTranslateValue(){
		if(this.translateOptions && !this.showValue){
			var a = []
			if(this._cookieService.get('lenguage') === 'eng'){
				a.push(this.translateOptions[0])
			}  else {
				a.push(this.translateOptions[1])
			}
			this.showValue = a;
		}
	}
	ngOnInit() {
		this.setAll();
		this.filteredList = this.list;
		if(this.unselctable){
			if(eval('this.filteredList[0].' + this.optionValue) != eval('this.unselctable.' + this.optionValue) ){
				this.filteredList.unshift(this.unselctable)
			}
		}
		this.setTranslateValue();


	}

	writeValue(value: any) {
		if (value !== undefined) {
			this.searchText = value;
		}
	}

	registerOnChange(fn) {
    	this.propagateChange = fn;
  	}

  	registerOnTouched() {}


	constructor(private _cookieService:CookieService, private _gS:GlobalService, private _languageChangeService: LanguageChangeService, private _langChangePipe: LangChangePipe){}
}
