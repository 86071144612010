import { Directive, Input, forwardRef } from "@angular/core";
import { Validator, AbstractControl, NG_VALIDATORS, Validators, ValidatorFn } from "@angular/forms";

@Directive({
	selector: '[min][ngModel]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => MinValidatorDirective),
		multi: true
	}]
})
export class MinValidatorDirective implements Validator {

	private _validator: ValidatorFn;
	@Input() public set min(value: string) {
		this._validator = Validators.min(parseFloat(value))
	}

	public validate(control: AbstractControl): { [key: string]: any } {
		return this._validator(control);
	}

}


@Directive({
	selector: '[max][ngModel]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => MaxValidatorDirective),
		multi: true
	}]
})
export class MaxValidatorDirective implements Validator {

	private _validator: ValidatorFn;
	@Input() public set max(value: string) {
		this._validator = Validators.max(parseFloat(value))
	}

	public validate(control: AbstractControl): { [key: string]: any } {
		return this._validator(control);
	}

}
