import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FilterService {

	/**
	 * @param {string} sortingType  Tipo del sort: DESC o ''
	 * @param {string} property
	 * @param {Object[]} list array de objetos a organizar
	 */
	sortByArgument(list: any[], property: string, sortingType = '', nullLast?): any[] {
		let sortOrder = 1;

		if (sortingType === "DESC") {
			sortOrder = -1;
		}

		return list.sort((a, b) => {
			// Checks for null values
			if (a[property] === null) {
				return nullLast ? 1 : 1 * sortOrder;
			}
			if (b[property] === null) {
				return nullLast ? -1 : -1 * sortOrder;
			}
			// Handles the rest
			let result;
			if (typeof a[property] == 'string' && typeof b[property] == 'string') {
				let valueA = Number(a[property]);
				let valueB = Number(b[property]);
				if ((valueA || valueA == 0) && (valueB || valueB == 0)) {
					result = (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
				} else {
					result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
				}
			} else {
				result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
			}
			return result * sortOrder;
		})
	}
	searchByMultipleValues(list: any[], properties: string[], textToSearch: string): any[] {
		let returnList = [];
		if (textToSearch) {
			returnList = list.filter(element => {
				let aev = this.normalizeString(textToSearch.toLowerCase());
				let retItem = false;
				for (let item of properties) {
					let text = eval("element." + item);
					if (text) {
						text = text.toString()
						let a = this.normalizeString(text.toLowerCase());
						if (a.indexOf(aev) > -1) {
							retItem = true;
						}
					}

				}
				return retItem;
			});

		} else {
			returnList = list
		}
		return returnList;
	}

	normalizeString(text) {
		return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	}

	searchFilter(list: any[], textToSearch: string, property: string): any[] {
		var returnList = [];
		if (textToSearch) {
			returnList = list.filter(element => {
				var text = eval("element." + property);
				if (text) {
					text = text.toString()
					var a = text.toLowerCase();
					var aev = textToSearch.toLowerCase();
					return a.indexOf(aev) > -1;
				} else {
					return false
				}

			});

		} else {
			returnList = list
		}

		return returnList;
	}
	filterBetweenNumber(list, argument, number_from, number_to) {
		var returnlist = [];
		if (number_from >= 0 || number_to >= 0) {
			for (var item of list) {
				var a = eval('item.' + argument);
				if (a) {
					if (number_from >= 0 && number_to >= 0) {
						if (a >= number_from && a <= number_to) {
							returnlist.push(item)
						}
					} else if (number_from >= 0) {
						if (a >= number_from) {
							returnlist.push(item)
						}
					} else {
						if (a <= number_to) {
							returnlist.push(item)
						}
					}
				}
			}
			return returnlist;

		} else {
			return list
		}
	}
	FilterRange(list, value, from, to) {
		var returnlist = [];
		if (from || to) {
			for (let item of list) {
				var returnThis = false;
				if (from && to) {
					if (parseInt(eval("item." + value)) >= parseInt(from) && parseInt(eval("item." + value)) <= parseInt(to)) {
						returnThis = true;
					}
				} else if (from) {
					if (parseInt(eval("item." + value)) >= parseInt(from)) {
						returnThis = true;
					}
				} else if (to) {
					parseInt(eval("item." + value))
					if (parseInt(eval("item." + value)) <= parseInt(to)) {
						returnThis = true;
					}
				}
				if (returnThis) {
					returnlist.push(item)
				}
			}
		} else {
			returnlist = list;
		}
		return returnlist;
	}

	filterRangeInSplitList(list, value, from, to) {
		var returnlist = [];
		if (from || to) {
			for (let item of list) {

				const splitList = item[value].split(',')
				var returnThis = false;

				if (splitList.length > 0) {
					splitList.forEach(itemValue => {
						if (from && to) {
							if (parseInt(itemValue) >= parseInt(from) && parseInt(itemValue) <= parseInt(to)) {
								returnThis = true;
							}
						} else if (from) {
							if (parseInt(itemValue) >= parseInt(from)) {
								returnThis = true;
							}
						} else if (to) {
							parseInt(itemValue)
							if (parseInt(itemValue) <= parseInt(to)) {
								returnThis = true;
							}
						}
						
					});
					if (returnThis) {
						returnlist.push(item)
					}	
				}
				
			}
		} else {
			returnlist = list;
		}
		return returnlist;
		
	}

	filterByArgument(list, argument, value) {
		if ((value || value == 0) && value != "") {
			return list.filter(element => {
				var a = eval("element." + argument)
				return a == value;
			});

		} else {
			return list;
		}
	}

	ElementInListElementsExcludeOption(list, list_id, elements, elements_id) {
		elements = elements ? elements : [];
		let newList = JSON.parse(JSON.stringify(list));
		let excludedElements = elements.filter(el => el.isExcluded);
		let notExcludedElements = elements.filter(el => !el.isExcluded);

		if (list && (elements && elements.length > 0)) {

			// Removes elements mark as "Excluded"
			if (excludedElements.length) {
				newList = newList.filter(item => {
					let exists = excludedElements.find(element => element[elements_id] == item[list_id]);
					return exists ? false : true;
				});
			}

			// Keeps elements that are not marked as "excluded"
			if (notExcludedElements.length) {
				newList = newList.filter(item => {
					let exists = notExcludedElements.find(element => element[elements_id] == item[list_id]);
					return exists ? true : false;
				});
			}

		} else {
			return list;
		}
		return newList;
	}

	ElementInListElementsSplitExcludeOption(list, list_id, elements, elements_id, splitCharacter = ',') {
		elements = elements ? elements : [];
		let newList = JSON.parse(JSON.stringify(list));
		let excludedElements = elements.filter(el => el.isExcluded);
		let notExcludedElements = elements.filter(el => !el.isExcluded);

		if (list && (elements && elements.length > 0)) {

			// Removes elements mark as "Excluded"
			if (excludedElements.length) {
				newList = newList.filter(item => {
					let exists = excludedElements.find(element => {
						if (!item[list_id]) return false;
						let splitList = item[list_id].split(splitCharacter);
						return splitList.find(i => element[elements_id] == i);
					});
					return exists ? false : true;
				});
			}

			// Keeps elements that are not marked as "excluded"
			if (notExcludedElements.length) {
				newList = newList.filter(item => {
					let exists = notExcludedElements.find(element => {
						if (!item[list_id]) return false;
						let splitList = item[list_id].split(splitCharacter);
						return splitList.find(i => element[elements_id] == i);
					});
					return exists ? true : false;
				});
			}

		} else {
			return list;
		}
		return newList;
	}

	filterDateRange(list, value, from, to) {
		var returnlist = [];
		if (from || to) {
			const FROM_DATE = this._datePipe.transform(from, 'yyyy-MM-dd')
			const TO_DATE = this._datePipe.transform(to, 'yyyy-MM-dd')
			for (let item of list) {
				var dateToCheck = eval("item." + value)
				if (dateToCheck) {
					var returnThis = false;
					var month: any = (new Date(dateToCheck).getMonth() + 1);
					if (month < 10) {
						month = '0' + month
					}
					var day: any = new Date(dateToCheck).getDate();
					if (day < 10) {
						day = '0' + day
					}
					// var dateCheck = new Date(dateToCheck).getFullYear() + '-' + month + '-' + day;
					var dateCheck = this._datePipe.transform(dateToCheck, 'yyyy-MM-dd')
					if (FROM_DATE && TO_DATE) {
						if (dateCheck >= FROM_DATE && dateCheck <= TO_DATE) {
							returnThis = true;
						}
					} else if (FROM_DATE) {
						if (dateCheck >= FROM_DATE) {
							returnThis = true;
						}
					} else if (TO_DATE) {
						if (dateCheck <= TO_DATE) {
							returnThis = true;
						}
					}
					if (returnThis) {
						returnlist.push(item)
					}
				}

			}
		} else {
			returnlist = list;
		}
		return returnlist;
	}


	sortByMultiplesArguments(list: any[], orderBy: any[], nullLast?: boolean) {
		let that = this;
		return list.sort(
			(a, b) => {
				let currentProp = 0;
				let sortOrder = 1;
				let order = null;
				while (order == null) {
					if (currentProp > orderBy.length - 1) {
						order = 0;
						return;
					}
					let property = JSON.parse(JSON.stringify(orderBy[currentProp])).replace(" DESC", "");
					if (a[property] === null && b[property] === null) {
						currentProp++;
						continue;
					}
					if (orderBy[currentProp].indexOf('DESC') > -1) sortOrder = -1;
					order = that.doValidatedSort(a, b, property, sortOrder, nullLast);
				}
				return order;
			}
		);
	}

	doValidatedSort(a: any, b: any, property: string, sortOrder: number, nullLast?: boolean) {
		let isNumeric = true;

		if (a[property] === null || a[property] === undefined || a[property] === false) {
			return nullLast ? 1 : 1 * sortOrder;
		}
		if (b[property] === null || b[property] === undefined || b[property] === false) {
			return nullLast ? -1 : -1 * sortOrder;
		}

		if (a[property] % 1 != 0 && b[property] % 1 != 0 && (typeof a[property] === "number") && (typeof b[property] === "number")) isNumeric = false;
		let result = `${this.normalizeString(a[property])}`.localeCompare(`${this.normalizeString(b[property])}`, undefined, {
			numeric: isNumeric,
			sensitivity: 'base'
		});

		return result * sortOrder;
	}

	constructor(private _datePipe: DatePipe) { }
}
