export type ColumnString = {
    label: string,
    key?: string,
    type?: string,
    showLabel?: string,
    isTotal?: boolean
}

export enum GraficoTipo {
    Barras,
    Lineas,
    Columnas,
    ColumnasApiladas,
    IndicadorCircular,
    Pie


}
export interface GraficoConfig  {
    nombre?: string;
    tipo: GraficoTipo,
    columnas?: [{ type: string, role: string }] | ColumnString[] | string[],
    opciones?: any,
    total?: string,
    max?: string
    conEtiquetas?: boolean
    cambiaDatos?: boolean
    totales?: any
    tabla?: any
    dataTable?: any
    unidad?: any
    unidadesEnColumnas?: boolean
    unidadesEnLegend?: boolean
    info?: string 
}